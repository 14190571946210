import { combineReducers } from "redux";
import { IAction } from "../../schema";

import {
  partnerDomainConst,
  IAddPartnerDomain,
  IGetPartnerDomain,
  IVerifyPartnerDomain
} from "../actions/domain";

const getPartnerDomainReducer = (
  state: IGetPartnerDomain = {},
  action: IAction<IGetPartnerDomain>
): IGetPartnerDomain => {
  const { type, payload } = action;
  if (type === partnerDomainConst.GET_PARTNER_DOMAIN) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          domain: payload.domain,
          form_options: payload.form_options
        };
      default:
        return state;
    }
  }

  return state;
};

const AddPartnerDomainReducer = (
  state: IAddPartnerDomain = {},
  action: IAction<IAddPartnerDomain>
): IAddPartnerDomain => {
  const { type, payload } = action;

  if (type === partnerDomainConst.ADD_PARTNER_DOMAIN) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          data: payload.data
        };
      default:
        return state;
    }
  }

  return state;
};

const VerifyPartnerDomainReducer = (
  state: IVerifyPartnerDomain = {},
  action: IAction<IVerifyPartnerDomain>
): IVerifyPartnerDomain => {
  const { type, payload } = action;

  if (type === partnerDomainConst.VERIFY_PARTNER_DOMAIN) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootPartnerDomainReducer = combineReducers({
  PartnerDomain: getPartnerDomainReducer,
  AddPartnerDomain: AddPartnerDomainReducer,
  VerifyPartnerDomain: VerifyPartnerDomainReducer
});

export interface IRPartnerDomain {
  PartnerDomain: IGetPartnerDomain;
  AddPartnerDomain: IAddPartnerDomain;
  VerifyPartnerDomain: IVerifyPartnerDomain;
}
