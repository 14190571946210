import { all } from "redux-saga/effects";
import {
  watchGetProfile,
  watchVerifyProfile,
  watchRegisterProfile,
  watchInvalidateToken,
  watchResendVerificationEmail,
  watchAddEmail,
  watchReauthAccount,
  watchUpdateProfile,
  watchTrackSignup,
  watchGetAllusers
} from "./user";
import {
  watchGetUsageInsights,
  watchGetFailedTransactionInsights,
  watchGetGraphInsights
} from "./insights";
import {
  watchGetVerificationSettings,
  watchSetSenderDomain,
  watchVerifySenderDomain,
  watchSendTestEmailSPF,
  watchResetSenderDomain,
  watchGetSenderDomainVerificationKeys,
  watchSendSenderVerificationKeysEmail,
  watchSetSenderIntegrationStatus,
  watchGetTrackOptionsStatus,
  watchSetTrackOptionsStatus,
  watchDedicatedIP,
  watchAllocateDedicatedIP
} from "./settings";
import { watchGetLogs, watchGetLogsByDate, watchGetLogsByEmail } from "./logs";
import {
  watchGenerateAccessKey,
  watchGetAccessKeys,
  watchRegenerateAccessSecret,
  watchRemoveAccessKey
} from "./access_keys";
import {
  watchGetReferralStatsByEmail,
  watchCreateReferralInvite
} from "./referral";
import {
  watchGetInvoices,
  watchGetPricing,
  // watchGetRedeemedPromo,
  watchGetSubscriptionEditURL,
  // watchGetSubscriptionMigrationStatus,
  // watchGetSubscriptionMigrationUrl,
  watchGetSubscriptionStatus,
  watchGetSubscriptionURL
  // watchRedeemPromo,
} from "./subscription";
import {
  watchGetSuppressionBounces,
  watchGetSuppressionSpams,
  watchGetSuppressionUnsubscribes,
  watchRemoveSuppressionBounce
} from "./suppression";
import {
  watchAddWebhookConfig,
  watchDeleteWebhookConfig,
  watchGetWebhookConfig,
  watchGetWebhookLogs,
  watchUpdateWebhookConfig
} from "./webhook";
import {
  watchAddProject,
  watchDeleteProject,
  watchGetProjectList,
  watchSetProjectLimits
} from "./projects";
import { watchGetAccountLimits } from "./account";
import {
  watchAddPartnerDomain,
  watchGetPartnerDomain,
  watchVerifyPartnerDomain
} from "./domain";

function* rootSaga() {
  yield all([
    // User
    watchGetProfile(),
    watchVerifyProfile(),
    watchRegisterProfile(),
    watchInvalidateToken(),
    watchResendVerificationEmail(),
    watchAddEmail(),
    watchReauthAccount(),
    watchUpdateProfile(),
    watchTrackSignup(),
    watchGetAllusers(),

    // Insights
    watchGetUsageInsights(),
    watchGetFailedTransactionInsights(),
    watchGetGraphInsights(),

    // Settings
    watchGetVerificationSettings(),
    watchSetSenderDomain(),
    watchVerifySenderDomain(),
    watchSendTestEmailSPF(),
    watchResetSenderDomain(),
    watchGetSenderDomainVerificationKeys(),
    watchSendSenderVerificationKeysEmail(),
    watchSetSenderIntegrationStatus(),
    watchGetTrackOptionsStatus(),
    watchSetTrackOptionsStatus(),

    // Logs
    watchGetLogs(),
    watchGetLogsByDate(),
    watchGetLogsByEmail(),

    // Access Keys
    watchGetAccessKeys(),
    watchGenerateAccessKey(),
    watchRegenerateAccessSecret(),
    watchRemoveAccessKey(),

    // Ref Service
    watchGetReferralStatsByEmail(),
    watchCreateReferralInvite(),

    watchGetSubscriptionURL(),
    watchGetSubscriptionStatus(),
    watchGetSubscriptionEditURL(),
    watchGetPricing(),

    watchGetSuppressionBounces(),
    watchRemoveSuppressionBounce(),
    watchGetSuppressionSpams(),
    watchGetSuppressionUnsubscribes(),
    // watchRedeemPromo(),
    // watchGetRedeemedPromo(),

    watchGetWebhookConfig(),
    watchAddWebhookConfig(),
    watchUpdateWebhookConfig(),
    watchDeleteWebhookConfig(),
    watchGetWebhookLogs(),

    watchGetProjectList(),
    watchAddProject(),
    watchDeleteProject(),
    watchSetProjectLimits(),

    watchGetAccountLimits(),
    watchGetInvoices(),
    // watchGetSubscriptionMigrationStatus(),
    // watchGetSubscriptionMigrationUrl(),
    watchGetPartnerDomain(),
    watchAddPartnerDomain(),

    watchDedicatedIP(),
    watchAllocateDedicatedIP(),
    watchVerifyPartnerDomain()
  ]);
}

export default rootSaga;
