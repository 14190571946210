import * as React from "react";
import { withRouter, RouteComponentProps, NavLink } from "react-router-dom";
import "./Sidebarnavigation.scss";
import { RootSchema } from "../../redux/reducers";
import { connect } from "react-redux";
import { IGetProfile } from "../../redux/actions/user";
import { IAppState } from "src/redux/actions/default";

export interface SidebarNavigationProps extends RouteComponentProps {
  hidePaths?: string[];
  isOnboardingView?: boolean;
  profile: IGetProfile;
  appState: IAppState;
}

export interface SidebarNavigationState {
  showSuppressionOptions: boolean;
}

class SidebarNavigation extends React.Component<
  SidebarNavigationProps,
  SidebarNavigationState
> {
  constructor(props: SidebarNavigationProps) {
    super(props);
    this.state = {
      showSuppressionOptions: false
    };
  }

  render() {
    const { hidePaths, isOnboardingView, location } = this.props;
    console.log(isOnboardingView);
    console.log(this.props.appState.isProjectViewOrAddView);
    if (
      this.props.appState.isProjectViewOrAddView ||
      (hidePaths && hidePaths.includes(location.pathname))
    ) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="sidebar-navigation">
          <nav>
            <ul className="d-flex flex-column align-items-center">
              <li className="d-flex align-items-center">
                <NavLink to="/dashboard" className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512"
                  >
                    <title>Dashboard</title>
                    <path
                      fill="currentColor"
                      d="M326.1 231.9l-47.5 75.5a31 31 0 01-7 7 30.11 30.11 0 01-35-49l75.5-47.5a10.23 10.23 0 0111.7 0 10.06 10.06 0 012.3 14z"
                    />
                    <path
                      d="M256 64C132.3 64 32 164.2 32 287.9a223.18 223.18 0 0056.3 148.5c1.1 1.2 2.1 2.4 3.2 3.5a25.19 25.19 0 0037.1-.1 173.13 173.13 0 01254.8 0 25.19 25.19 0 0037.1.1l3.2-3.5A223.18 223.18 0 00480 287.9C480 164.2 379.7 64 256 64z"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M256 128v32M416 288h-32M128 288H96M165.49 197.49l-22.63-22.63M346.51 197.49l22.63-22.63"
                    />
                  </svg>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              {/* <li className="d-flex align-items-center">
                <NavLink to="/analytics" className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512"
                  >
                    <title>Analytics</title>
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M344 280l88-88M232 216l64 64M80 320l104-104"
                    />
                    <circle
                      cx="456"
                      cy="168"
                      r="24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <circle
                      cx="320"
                      cy="304"
                      r="24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <circle
                      cx="208"
                      cy="192"
                      r="24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <circle
                      cx="56"
                      cy="344"
                      r="24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                  </svg>
                  <span>Analytics</span>
                </NavLink>
              </li> */}
              {/* <li className="d-flex align-items-center">
                <NavLink to="/logs" className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Logs</title>
                    <path
                      d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
                      fill="none"
                      stroke="currentColor"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <path
                      d="M256 56v120a32 32 0 0032 32h120"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                  </svg>

                  <span>Logs</span>
                </NavLink>
              </li> */}
              <li className="d-flex align-items-center">
                <NavLink
                  to="/access-keys"
                  className="d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512"
                  >
                    <title>Key</title>
                    <path
                      d="M218.1 167.17c0 13 0 25.6 4.1 37.4-43.1 50.6-156.9 184.3-167.5 194.5a20.17 20.17 0 00-6.7 15c0 8.5 5.2 16.7 9.6 21.3 6.6 6.9 34.8 33 40 28 15.4-15 18.5-19 24.8-25.2 9.5-9.3-1-28.3 2.3-36s6.8-9.2 12.5-10.4 15.8 2.9 23.7 3c8.3.1 12.8-3.4 19-9.2 5-4.6 8.6-8.9 8.7-15.6.2-9-12.8-20.9-3.1-30.4s23.7 6.2 34 5 22.8-15.5 24.1-21.6-11.7-21.8-9.7-30.7c.7-3 6.8-10 11.4-11s25 6.9 29.6 5.9c5.6-1.2 12.1-7.1 17.4-10.4 15.5 6.7 29.6 9.4 47.7 9.4 68.5 0 124-53.4 124-119.2S408.5 48 340 48s-121.9 53.37-121.9 119.17zM400 144a32 32 0 11-32-32 32 32 0 0132 32z"
                      fill="none"
                      stroke="currentColor"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                  </svg>
                  <span>API Credentials</span>
                </NavLink>
              </li>
              <li className="d-flex align-items-center">
                <NavLink to="/users" className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512"
                  >
                    <title>Users</title>
                    <path
                      d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <path
                      d="M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z"
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    />
                    <path
                      d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <path
                      d="M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    />
                  </svg>
                  <span>Users</span>
                </NavLink>
              </li>
              <li className="d-flex align-items-center">
                <NavLink
                  to="/domain-whitelisting"
                  className="d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512"
                  >
                    <title>Domain Whitelisting</title>
                    <path
                      d="M256 48C141.13 48 48 141.13 48 256s93.13 208 208 208 208-93.13 208-208S370.87 48 256 48z"
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    />
                    <path
                      d="M256 48c-58.07 0-112.67 93.13-112.67 208S197.93 464 256 464s112.67-93.13 112.67-208S314.07 48 256 48z"
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    />
                    <path
                      d="M117.33 117.33c38.24 27.15 86.38 43.34 138.67 43.34s100.43-16.19 138.67-43.34M394.67 394.67c-38.24-27.15-86.38-43.34-138.67-43.34s-100.43 16.19-138.67 43.34"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M256 48v416M464 256H48"
                    />
                  </svg>
                  <span>Domain Whitelisting</span>
                </NavLink>
              </li>
              <li className="d-flex align-items-center">
                <NavLink
                  to="/dedicated-ip"
                  className="d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Dedicated IP</title>
                    <rect
                      x="64"
                      y="176"
                      width="384"
                      height="256"
                      rx="28.87"
                      ry="28.87"
                      fill="none"
                      stroke="currentColor"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M144 80h224M112 128h288"
                    />
                  </svg>
                  <span>Dedicated IP</span>
                </NavLink>
              </li>
              {/* <li className="d-flex align-items-center">
                <NavLink to="/test" className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Test Email</title>
                    <path
                      d="M53.12 199.94l400-151.39a8 8 0 0110.33 10.33l-151.39 400a8 8 0 01-15-.34l-67.4-166.09a16 16 0 00-10.11-10.11L53.46 215a8 8 0 01-.34-15.06zM460 52L227 285"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                  </svg>
                  <span>Test Email</span>
                </NavLink>
              </li> */}
              {/* <li className="d-flex align-items-center">
                <NavLink
                  to="#"
                  onClick={() => {
                    this.setState({
                      showSuppressionOptions: !this.state
                        .showSuppressionOptions,
                    });
                  }}
                  isActive={match => {
                    if (match?.url.includes("/suppressions")) {
                      return true;
                    }
                    return false;
                  }}
                  className="d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Suppressions</title>
                    <path
                      d="M320 96H88a40 40 0 00-40 40v240a40 40 0 0040 40h334.73a40 40 0 0040-40V239"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M112 160l144 112 87-65.67"
                    />
                    <circle fill="#2f55d4" cx="431.95" cy="128.05" r="47.95" />
                    <path
                      fill="#2f55d4"
                      d="M432 192a63.95 63.95 0 1163.95-63.95A64 64 0 01432 192zm0-95.9a32 32 0 1031.95 32 32 32 0 00-31.95-32z"
                    />
                  </svg>
                  <span>Suppressions</span>
                </NavLink>
              </li> */}

              {/* {this.state.showSuppressionOptions ? (
                <>
                  <li className="d-flex align-items-center ml-4">
                    <NavLink
                      to="/suppressions/bounces"
                      className="d-flex align-items-center ml-4"
                    >
                      <span>Bounces</span>
                    </NavLink>
                  </li>
                  <li className="d-flex align-items-center ml-4">
                    <NavLink
                      to="/suppressions/spam-reports"
                      className="d-flex align-items-center ml-4"
                    >
                      <span>Spam Reports</span>
                    </NavLink>
                  </li>
                  <li className="d-flex align-items-center ml-4">
                    <NavLink
                      to="/suppressions/unsubscribes"
                      className="d-flex align-items-center ml-4"
                    >
                      <span>Unsubscribes</span>
                    </NavLink>
                  </li>
                </>
              ) : null} */}

              {/* <li className="d-flex align-items-center">
                <NavLink to="/webhook" className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512"
                    className="ionicon"
                  >
                    <title>Swap Horizontal</title>
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M304 48l112 112-112 112M398.87 160H96M208 464L96 352l112-112M114 352h302"
                    />
                  </svg>
                  <span>Webhook</span>
                </NavLink>
              </li> */}

              {/* <li className="d-flex align-items-center">
                <NavLink
                  to="/sender-auth"
                  className="d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Sender Authentication</title>
                    <path
                      d="M448.08 256.08c0-106-86-192-192-192s-192 86-192 192 86 192 192 192 192-85.97 192-192z"
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    />
                    <path
                      d="M300.81 358.29c-20.83 7.42-34.05 9.59-54.19 9.59-61.17 0-106.39-50.07-101-111.84S205 144.21 266.14 144.21c68.92 0 106.79 45.55 101.47 106.55-4 45.54-32.8 58.66-47.89 56-14.2-2.55-25.92-15.52-23.75-40.35l5.62-44.66c-7.58-9.17-28.11-18-49.93-14.54C231.77 210.3 209 228 206.56 256s14.49 50.84 39.93 50.84 47.86-18.39 50.69-50.84"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="28"
                    />
                  </svg>
                  <span>Sender Authentication</span>
                </NavLink>
              </li> */}
              {/* <li className="d-flex align-items-center">
                <NavLink
                  to="/guide/integrate"
                  className="d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Integration</title>
                    <path
                      d="M256 160c16-63.16 76.43-95.41 208-96a15.94 15.94 0 0116 16v288a16 16 0 01-16 16c-128 0-177.45 25.81-208 64-30.37-38-80-64-208-64-9.88 0-16-8.05-16-17.93V80a15.94 15.94 0 0116-16c131.57.59 192 32.84 208 96zM256 160v288"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                  </svg>
                  <span>Integration Guide</span>
                </NavLink>
              </li> */}
              {/* <li className="d-flex align-items-center">
                <a
                  href="https://mailazy.com/docs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Documentation</title>
                    <path
                      d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
                      fill="none"
                      stroke="currentColor"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <path
                      d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                  </svg>
                  <span>Documentation</span>
                </a>
              </li> */}
            </ul>
          </nav>
          <div className="needhelp">
            <h5>Need help?</h5>
            <a
              href="https://mailazy.com/docs/"
              target="_blank"
              rel="noopener noreferrer"
              className="mb-3 d-block"
            >
              How to set up your Mailazy account?
            </a>
            <p className="mb-2">We’re here for you! Get in touch.</p>
            <div className="d-flex flex-wrap">
              <a
                href="https://mailazy.com/contact-us"
                target="_blank"
                rel="noopener noreferrer"
                className="sidebar-contact ui blue tiny basic button"
              >
                Contact us
              </a>
              <a
                // href="https://mailazy.com/feature-request.html"
                href="https://twitter.com/heymailazy"
                target="_blank"
                rel="noopener noreferrer"
                className="sidebar-twitter ui blue tiny basic button ml-2"
              >
                Twitter
              </a>
              {/* <NavLink
                to="/refer-promo"
                className="w-100 ui blue tiny basic button mt-2"
              >
                Support Mailazy
              </NavLink> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = (state: RootSchema) => {
  return {
    profile: state.user.profile,
    appState: state.default.appState
  };
};

export default connect(mapState)(withRouter(SidebarNavigation));
