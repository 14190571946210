import { combineReducers } from "redux";
import { IAction } from "../../schema";
import {
  subscriptionConst,
  IGetSubscriptionURL,
  IGetSubscriptionStatus,
  IGetSubscriptionEditURL,
  IGetPricing,
  IRedeemPromo,
  IGetRedeemedPromo,
  IGetInvoices,
  IGetSubscriptionMigrationStatus,
  IGetSubscriptionMigrateURL
} from "../actions/subscription";

const getSubscriptionURLReducer = (
  state: IGetSubscriptionURL = {},
  action: IAction<IGetSubscriptionURL>
): IGetSubscriptionURL => {
  const { type, payload } = action;

  if (type === subscriptionConst.GET_SUBSCRIBE_URL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const getSubscriptionStatusReducer = (
  state: IGetSubscriptionStatus = {},
  action: IAction<IGetSubscriptionStatus>
): IGetSubscriptionStatus => {
  const { type, payload } = action;

  if (type === subscriptionConst.GET_SUBSCRIPTION_STATUS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const getSubscriptionEditURLReducer = (
  state: IGetSubscriptionEditURL = {},
  action: IAction<IGetSubscriptionEditURL>
): IGetSubscriptionEditURL => {
  const { type, payload } = action;

  if (type === subscriptionConst.GET_SUBSCRIBE_EDIT_URL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const getPricingReducer = (
  state: IGetPricing = {},
  action: IAction<IGetPricing>
): IGetPricing => {
  const { type, payload } = action;

  if (type === subscriptionConst.GET_PRICING) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const redeemPromoReducer = (
  state: IRedeemPromo = {},
  action: IAction<IRedeemPromo>
): IRedeemPromo => {
  const { type, payload } = action;

  if (type === subscriptionConst.REDEEM_PROMO) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const getRedeemedPromoReducer = (
  state: IGetRedeemedPromo = {},
  action: IAction<IGetRedeemedPromo>
): IGetRedeemedPromo => {
  const { type, payload } = action;

  if (type === subscriptionConst.GET_REDEEMED_PROMO) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const getInvoicesReducer = (
  state: IGetInvoices = {},
  action: IAction<IGetInvoices>
): IGetInvoices => {
  const { type, payload } = action;

  if (type === subscriptionConst.GET_INVOICES) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const getSubscriptionMigrationStatusReducer = (
  state: IGetSubscriptionMigrationStatus = {},
  action: IAction<IGetSubscriptionMigrationStatus>
): IGetSubscriptionMigrationStatus => {
  const { type, payload } = action;

  if (type === subscriptionConst.GET_SUBSCRIPTION_MIGRATION_STATUS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const getSubscriptionMigrationUrlReducer = (
  state: IGetSubscriptionMigrateURL = {},
  action: IAction<IGetSubscriptionMigrateURL>
): IGetSubscriptionMigrateURL => {
  const { type, payload } = action;

  if (type === subscriptionConst.GET_SUBSCRIBE_MIGRATION_URL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootSubscriptionReducer = combineReducers({
  SubscriptionURL: getSubscriptionURLReducer,
  SubscriptionStatus: getSubscriptionStatusReducer,
  SubscriptionEditURL: getSubscriptionEditURLReducer,
  Pricing: getPricingReducer,
  RedeemPromo: redeemPromoReducer,
  RedeemedPromo: getRedeemedPromoReducer,
  Invoices: getInvoicesReducer,
  SubscriptionMigrationStatus: getSubscriptionMigrationStatusReducer,
  SubscriptionMigrationUrl: getSubscriptionMigrationUrlReducer
});

export interface IRSubscription {
  SubscriptionURL: IGetSubscriptionURL;
  SubscriptionStatus: IGetSubscriptionStatus;
  SubscriptionEditURL: IGetSubscriptionEditURL;
  Pricing: IGetPricing;
  RedeemPromo: IRedeemPromo;
  RedeemedPromo: IGetRedeemedPromo;
  Invoices: IGetInvoices;
  SubscriptionMigrationStatus: IGetSubscriptionMigrationStatus;
  SubscriptionMigrationUrl: IGetSubscriptionMigrateURL;
}
