import { combineReducers } from "redux";
import { IAction } from "../../schema";
import {
  insightsConst,
  IGetUsageInsights,
  IGetFailedTransactionInsights,
  IGetGraphInsights,
} from "../actions/insights";

const usageInsightsReducer = (
  state: IGetUsageInsights = {},
  action: IAction<IGetUsageInsights>
): IGetUsageInsights => {
  const { type, payload } = action;

  if (type === insightsConst.GET_USAGE_INSIGHTS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          ...payload
        };
      default:
        return state;
    }
  }

  return state;
};

const failedTransactionInsightsReducer = (
  state: IGetFailedTransactionInsights = {},
  action: IAction<IGetFailedTransactionInsights>
): IGetFailedTransactionInsights => {
  const { type, payload } = action;

  if (type === insightsConst.GET_FAILED_TRANSACTION_INSIGHTS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const graphInsightsReducer = (
  state: IGetGraphInsights = {},
  action: IAction<IGetGraphInsights>
): IGetGraphInsights => {
  const { type, payload } = action;

  if (type === insightsConst.GET_GRAPH_INSIGHTS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":

        if (payload.insights == null || payload.insights.length === 0) {
          return {
            ...state,
            state: "success",
            has_data: false,
          };
        }

        return {
          ...state,
          state: "success",
          insights: payload.insights,
          has_data: true,
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootInsightsReducer = combineReducers({
  UsageInsights: usageInsightsReducer,
  FailedTransactionInsights: failedTransactionInsightsReducer,
  GraphInsights: graphInsightsReducer,
});

export interface IRInsights {
  UsageInsights: IGetUsageInsights;
  FailedTransactionInsights: IGetFailedTransactionInsights;
  GraphInsights: IGetGraphInsights;
}
