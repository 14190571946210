import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { AdminHttp, ProjectInsightsHttp } from "../../services/http";
import {
  addWebhookConfigAction,
  deleteWebhookConfigAction,
  getWebhookConfigAction,
  getWebhookLogsAction,
  IAddWebhookConfigArgs,
  IDeleteWebhookConfigArgs,
  IGetWebhookConfig,
  IGetWebhookConfigArgs,
  IGetWebhookLog,
  IGetWebhookLogArgs,
  IUpdateWebhookConfig,
  IUpdateWebhookConfigArgs,
  updateWebhookConfigAction,
  webhookConst
} from "../actions/webhook";

function* getWebhookConfigHandler(args: IGetWebhookConfigArgs) {
  try {
    yield put(getWebhookConfigAction.loading());

    const resp: IGetWebhookConfig = yield AdminHttp({
      token: args.token
    }).get(`/webhook`);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getWebhookConfigAction.success(resp));
  } catch (e) {
    yield put(getWebhookConfigAction.error(e.message));
  }
}

export function* watchGetWebhookConfig() {
  while (true) {
    const { payload }: IAction<IGetWebhookConfigArgs> = yield take(
      webhookConst.GET_WEBHOOK_CONFIG
    );
    yield call(getWebhookConfigHandler, payload);
  }
}

function* addWebhookConfigHandler(args: IAddWebhookConfigArgs) {
  try {
    yield put(addWebhookConfigAction.loading());

    const resp: IGetWebhookConfig = yield AdminHttp({
      token: args.token
    }).post(`/webhook`, {}, JSON.stringify(args));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(addWebhookConfigAction.success(resp));
  } catch (e) {
    yield put(addWebhookConfigAction.error(e.message));
  }
}

export function* watchAddWebhookConfig() {
  while (true) {
    const { payload }: IAction<IAddWebhookConfigArgs> = yield take(
      webhookConst.ADD_WEBHOOK_CONFIG
    );
    yield call(addWebhookConfigHandler, payload);
  }
}

function* updateWebhookConfigHandler(args: IUpdateWebhookConfigArgs) {
  try {
    yield put(updateWebhookConfigAction.loading());

    const resp: IUpdateWebhookConfig = yield AdminHttp({
      token: args.token
    }).put(`/webhook`, {}, JSON.stringify(args));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(updateWebhookConfigAction.success(resp));
  } catch (e) {
    yield put(updateWebhookConfigAction.error(e.message));
  }
}

export function* watchUpdateWebhookConfig() {
  while (true) {
    const { payload }: IAction<IUpdateWebhookConfigArgs> = yield take(
      webhookConst.UPDATE_WEBHOOK_CONFIG
    );
    yield call(updateWebhookConfigHandler, payload);
  }
}

function* deleteWebhookConfigHandler(args: IDeleteWebhookConfigArgs) {
  try {
    yield put(deleteWebhookConfigAction.loading());

    const resp: IUpdateWebhookConfig = yield AdminHttp({
      token: args.token
    }).delete(`/webhook`, { id: args.id });

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(deleteWebhookConfigAction.success(resp));
  } catch (e) {
    yield put(deleteWebhookConfigAction.error(e.message));
  }
}

export function* watchDeleteWebhookConfig() {
  while (true) {
    const { payload }: IAction<IDeleteWebhookConfigArgs> = yield take(
      webhookConst.DELETE_WEBHOOK_CONFIG
    );
    yield call(deleteWebhookConfigHandler, payload);
  }
}

function* getWebhookLogsHandler(args: IGetWebhookLogArgs) {
  try {
    yield put(getWebhookLogsAction.loading());

    const resp: IGetWebhookLog = yield ProjectInsightsHttp({
      token: args.token
    }).get(`/webhook/logs`, { cursor: args.id || "" });

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getWebhookLogsAction.success(resp));
  } catch (e) {
    yield put(getWebhookLogsAction.error(e.message));
  }
}

export function* watchGetWebhookLogs() {
  while (true) {
    const { payload }: IAction<IGetWebhookLogArgs> = yield take(
      webhookConst.GET_WEBHOOK_LOGS
    );
    yield call(getWebhookLogsHandler, payload);
  }
}
