function getConfig() {
  return {
    idp_apikey:
      process.env.REACT_APP_IDP_KEY || "4bc2842a-9336-4641-8aef-6056d66395fe",
    idp_endpoint:
      process.env.REACT_APP_IDP_ENDPOINT || "https://api.loginradius.com",
    idp_hub_endpoint:
      process.env.REACT_APP_HUB_ENDPOINT ||
      "https://auth.mailazy.com/requesthandler.aspx",
    idp_sott:
      process.env.REACT_APP_IDP_SOTT ||
      "wNwKG3RZpBIeAKQSEX0MzNny2HyIJ3cjyaAFNjjzeK8tnHV5/1QEDS8b5fzaKGbJXF+hQ/+z3Bsl3rXu/BZWMvUDB0XLAi0ShYYX4XZu6tI=*2138a868a1497146f5850f222d8cb346",
    api_endpoint:
      process.env.REACT_APP_API_ENDPOINT || " https://devpartner.mailazy.com",
    gtm_id: process.env.REACT_APP_GTM_ID || "GTM-5PFG66Q",
    hj_id: process.env.REACT_APP_HJ_ID || "2186538",
    hj_sv: process.env.REACT_APP_HJ_SV || "6",
    ref_service_endpoint: process.env.REACT_APP_REF_SERVICE_ENDPOINT || "https://referral.mail7.io",
    ref_service_key: process.env.REACT_APP_REF_SERVICE_KEY || "b2f17d7b-2114-486c-8918-30a44784d1c1",
  };
}

export default getConfig();