import { createAction, ProjectArgs } from ".";
import { IAPIError } from "../../schema";

export const logsConst = {
  GET_LOGS: "GET_LOGS",
  GET_LOGS_BY_DATE: "GET_LOGS_BY_DATE",
  GET_LOGS_BY_EMAIL: "GET_LOGS_BY_EMAIL"
};

export interface IGetLogsArgs extends ProjectArgs {
  token: string;
  prev_cursor?: string;
  next_cursor?: string;
  status?: number;
}

export interface Logs {
  attempts: number;
  status: number;
  remote_response_code: number;
  meta: Meta;
  created_at: string;
  failure_phrases: Array<string>;
  attachment_file_names: Array<string>;
  unique_opens: Array<UniqueOpenTracks>;
  unique_clicks: Array<UniqueClickTracks>;
}

export interface UniqueOpenTracks {
  opened_at: Date;
}

export interface UniqueClickTracks {
  url: string;
  clicked_at: Date;
}

interface Meta {
  sub: string;
  headers: Record<string, string>;
}

interface LogsResp {
  logs?: Array<Logs>;
  prev_cursor?: string;
  next_cursor?: string;
  is_first_page?: boolean;
  is_last_page?: boolean;
  has_data?: boolean;
}

export interface IGetLogs extends LogsResp, IAPIError {}

export const getLogsAction = createAction<IGetLogs, IGetLogsArgs>(
  logsConst.GET_LOGS
);

export interface IGetLogsByDateArgs extends ProjectArgs {
  token: string;
  page?: string;
  status?: number;
  from: string;
  to: string;
}

export interface IGetLogsByDate extends LogsResp, IAPIError {}

export const getLogsByDateAction = createAction<
  IGetLogsByDate,
  IGetLogsByDateArgs
>(logsConst.GET_LOGS_BY_DATE);

// Logs by Email
export interface IGetLogsByEmailArgs extends ProjectArgs {
  token: string;
  page?: string;
  status?: number;
  from?: string;
  rcpt_to?: string;
  from_domain?: string;
}
export interface IGetLogsByEmail extends LogsResp, IAPIError {}

export const getLogsByEmailAction = createAction<
  IGetLogsByEmail,
  IGetLogsByEmailArgs
>(logsConst.GET_LOGS_BY_EMAIL);
