import { combineReducers } from "redux";
import { IAction } from "../../schema";
import {
  IAddProject,
  IDeleteProject,
  IGetProjectList,
  ISetProjectLimits,
  projectConst
} from "../actions/projects";

const getProjectListReducer = (
  state: IGetProjectList = {},
  action: IAction<IGetProjectList>
): IGetProjectList => {
  const { type, payload } = action;

  if (type === projectConst.GET_PROJECT_LIST) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", data: payload.data };
      default:
        return state;
    }
  }

  return state;
};

const addProjectReducer = (
  state: IAddProject = {},
  action: IAction<IAddProject>
): IAddProject => {
  const { type, payload } = action;

  if (type === projectConst.ADD_PROJECT) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          added: payload.added
        };
      default:
        return state;
    }
  }

  return state;
};

const setProjectLimitsReducer = (
  state: ISetProjectLimits = {},
  action: IAction<ISetProjectLimits>
): ISetProjectLimits => {
  const { type, payload } = action;

  if (type === projectConst.SET_LIMIT) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          added: payload.added
        };
      default:
        return state;
    }
  }

  return state;
};

const deleteProjectReducer = (
  state: IDeleteProject = {},
  action: IAction<IDeleteProject>
): IDeleteProject => {
  const { type, payload } = action;

  if (type === projectConst.DELETE_PROJECT) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          added: payload.added
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootProjectReducer = combineReducers({
  List: getProjectListReducer,
  Add: addProjectReducer,
  SetLimit: setProjectLimitsReducer,
  Delete: deleteProjectReducer
});

export interface IRProject {
  List: IGetProjectList;
  Add: IAddProject;
  SetLimit: ISetProjectLimits;
  Delete: IDeleteProject;
}
