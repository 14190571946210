import { combineReducers } from "redux";
import { IAction } from "../../schema";
import {
  referralConst,
  IGetReferralStatsByEmail,
  ICreateReferralInvite
} from "../actions/referral";

const getReferralStatsByEmailReducer = (
  state: IGetReferralStatsByEmail = {},
  action: IAction<IGetReferralStatsByEmail>
): IGetReferralStatsByEmail => {
  const { type, payload } = action;

  if (type === referralConst.GET_STATS_BY_EMAIL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          Data: payload.Data,
          Status: payload.Status
        };
      default:
        return state;
    }
  }

  return state;
};

const createReferralInviteReducer = (
  state: ICreateReferralInvite = {},
  action: IAction<ICreateReferralInvite>
): ICreateReferralInvite => {
  const { type, payload } = action;

  if (type === referralConst.CREATE_INVITE) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          Data: payload.Data,
          Status: payload.Status
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootReferralReducer = combineReducers({
  ReferralStatsByEmail: getReferralStatsByEmailReducer,
  CreateReferralInvite: createReferralInviteReducer
});

export interface IRReferral {
  ReferralStatsByEmail: IGetReferralStatsByEmail;
  CreateReferralInvite: ICreateReferralInvite;
}
