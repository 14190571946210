import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootSchema } from "../../redux/reducers";
import { getCookie } from "src/utils/urls";
import { Button, Header, Message, Modal, Table } from "semantic-ui-react";
import {
  allocateDedicatedIP,
  getDedicatedIP,
  IAllocateDedicatedIP,
  IAllocateDedicatedIPArgs,
  IGetDedicatedIP,
  IGetDedicatedIPArgs
} from "src/redux/actions/settings";
import { IAppState } from "src/redux/actions/default";
import "./dedicated_ip.scss";
import { toast } from "react-toastify";

export interface DedicatedIpProps {
  appState: IAppState;
  DedicatedIPs: IGetDedicatedIP;
  AllocateDedicatedIP: IAllocateDedicatedIP;

  getDedicatedIPAction: (args: IGetDedicatedIPArgs) => void;
  allocateDedicatedIPAction: (args: IAllocateDedicatedIPArgs) => void;
}

export interface DedicatedIpState {
  auth: string;
  showAddModal?: boolean;
  loading?: boolean;
}

class DedicatedIp extends React.Component<DedicatedIpProps, DedicatedIpState> {
  constructor(props: DedicatedIpProps) {
    super(props);
    this.state = { auth: getCookie("accessToken") || "" };
    this.props.getDedicatedIPAction({ token: this.state.auth });
  }

  componentDidUpdate(pProps: DedicatedIpProps) {
    if (pProps.AllocateDedicatedIP.state === "loading") {
      const { AllocateDedicatedIP } = this.props;
      if (AllocateDedicatedIP.state === "success") {
        this.setState({ loading: false });
        this.props.getDedicatedIPAction({ token: this.state.auth });
        this._handleModalClose();
      } else if (AllocateDedicatedIP.state === "error") {
        toast.error(AllocateDedicatedIP.error);
        this._handleModalClose();
      }
    }
  }

  private _handleAddDedicatedIP = () => {
    this.setState({ showAddModal: true });
  };
  private _handleModalClose = () => {
    this.setState({ showAddModal: false });
  };

  private _allocateDedicatedIP = () => {
    this.setState({ loading: true });
    this.props.allocateDedicatedIPAction({
      token: this.state.auth
    });
  };

  render() {
    return (
      <div className="page-content">
        <section>
          <div className="content">
            <div className="container">
              <div className="page-title d-flex align-items-center justify-content-start pb-3 mb-4">
                <div className="title">
                  <h2>Dedicated IPs</h2>
                </div>
                <Button
                  primary
                  size="tiny"
                  color="blue"
                  floated="right"
                  style={{ marginLeft: "auto" }}
                  onClick={this._handleAddDedicatedIP}
                >
                  Add Dedicated IP
                </Button>
              </div>

              {this.props.DedicatedIPs.data ? (
                <Table celled padded>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell singleLine>IP</Table.HeaderCell>
                      <Table.HeaderCell>rDNS</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.props.DedicatedIPs.data.map(ip => {
                      return (
                        <Table.Row key={ip.allocation_id}>
                          <Table.Cell>
                            <Header as="h5" textAlign="left">
                              {ip.ip_address}
                            </Header>
                          </Table.Cell>
                          <Table.Cell singleLine>{ip.reverse_dns}</Table.Cell>
                          <Table.Cell>Assigned</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              ) : (
                <Message
                  icon="paperclip"
                  header="No Data Found"
                  content="You do not have any dedicated IP allocated!"
                />
              )}

              <Modal
                size="small"
                open={this.state.showAddModal}
                onClose={this._handleModalClose}
                closeIcon
                closeOnEscape={false}
                closeOnDimmerClick={false}
                closeOnDocumentClick={false}
              >
                <Modal.Header>Add Dedicated IP</Modal.Header>
                <Modal.Content>
                  {this.state.loading ? (
                    <></>
                  ) : (
                    <>
                      <Message className="mb-5" floating>
                        <Message.Header>Attention</Message.Header>
                        <p>
                          You will be charged additionally $20 for each
                          dedicated IP.
                        </p>
                      </Message>
                    </>
                  )}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    content="Allocate"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={this._allocateDedicatedIP}
                    loading={this.props.AllocateDedicatedIP.state === "loading"}
                    positive
                  />
                </Modal.Actions>
              </Modal>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapState = (state: RootSchema) => {
  return {
    appState: state.default.appState,
    verificationSettings: state.settings.SenderVerificationSettings,
    DedicatedIPs: state.settings.GetDedicatedIP,
    AllocateDedicatedIP: state.settings.AllocateDedicatedIP
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    getDedicatedIPAction: (args: IGetDedicatedIPArgs) =>
      dispatch(getDedicatedIP.dispatch(args)),
    allocateDedicatedIPAction: (args: IAllocateDedicatedIPArgs) =>
      dispatch(allocateDedicatedIP.dispatch(args))
  };
};

export default connect(mapState, mapDispatch)(DedicatedIp);
