import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./assets/scss/Base.scss";
import "./assets/scss/icon.css";
import "./assets/scss/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import App from "./containers/App";
import createStore from "./redux/store/index";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { hotjar } from "react-hotjar";
import conf from "./config";
import TagManager from "react-gtm-module";

// Configure Store
const store = createStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

TagManager.initialize({ gtmId: conf.gtm_id });
hotjar.initialize(parseInt(conf.hj_id), parseInt(conf.hj_sv));
serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: (registration: ServiceWorkerRegistration) => {
//     if (window.confirm("Update available. Do you want to reload?")) {
//       window.location.reload();
//     }
//   },
// });
