import { createAction } from ".";
import { IReferralError } from "../../schema";

export const referralConst = {
  GET_STATS_BY_EMAIL: "GET_STATS_BY_EMAIL",
  CREATE_INVITE: "CREATE_INVITE"
};

export interface IReferralStatsByEmail {
  Data?: IReferralStatsByEmailData;
}

export interface IReferralStatsByEmailData {
  ref_id?: string;
  invite_id?: string;
  is_block?: boolean;
  identity?: IReferralStatsByEmailDataIdentity;
  referral_stats?: IReferralStatsByEmailDataStats;
}

export interface IReferralStatsByEmailDataIdentity {
  email?: string;
}
export interface IReferralStatsByEmailDataStats {
  visits?: string;
  conversions?: string;
}

export interface IGetReferralStatsByEmail
  extends IReferralStatsByEmail,
    IReferralError {}
export interface IGetReferralStatsByEmailArgs {
  email: string;
}

export const getReferralStatsByEmailAction = createAction<
  IGetReferralStatsByEmail,
  IGetReferralStatsByEmailArgs
>(referralConst.GET_STATS_BY_EMAIL);

export interface IReferralCreateInvite {
  Data?: IReferralCreateInviteData;
}
export interface IReferralCreateInviteData {
  ref_id?: string;
  invite_id?: string;
}

export interface ICreateReferralInvite
  extends IReferralStatsByEmail,
    IReferralError {}
export interface ICreateReferralInviteArgs {
  email: string;
}

export const createReferralInviteAction = createAction<
  ICreateReferralInvite,
  ICreateReferralInviteArgs
>(referralConst.CREATE_INVITE);
