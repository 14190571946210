import { createAction, ProjectArgs } from ".";
import { ILRError, IAPIError, ISideEffects } from "../../schema";

export const userConst = {
  FETCH_PROFILE: "FETCH_PROFILE",
  VERIFY_PROFILE: "VERIFY_PROFILE",
  REGISTER_PROFILE: "REGISTER_PROFILE",
  INVALIDATE_TOKEN: "INVALIDATE_TOKEN",
  RESEND_VERIFICATION_EMAIL: "RESEND_VERIFICATION_EMAIL",
  ADD_EMAIL: "ADD_EMAIL",
  REAUTH_ACCOUNT: "REAUTH_ACCOUNT",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  TRACK_SIGNUP: "TRACK_SIGNUP",
  GET_USERS: 'GET_USERS',
};

export interface IProfile {
  Identities: Identity[];
  Password: null;
  LastPasswordChangeDate: null;
  PasswordExpirationDate: null;
  LastPasswordChangeToken: null;
  EmailVerified: boolean;
  IsActive: boolean;
  IsDeleted: boolean;
  Uid: string;
  CustomFields: CustomFields;
  IsEmailSubscribed: boolean;
  UserName: null;
  NoOfLogins: number;
  PhoneId: null;
  PhoneIdVerified: boolean;
  Roles: null;
  ExternalUserLoginId: null;
  RegistrationProvider: null;
  IsLoginLocked: boolean;
  LoginLockedType: string;
  LastLoginLocation: string;
  RegistrationSource: string;
  IsCustomUid: boolean;
  UnverifiedEmail: Email[] | null;
  IsSecurePassword: null;
  PrivacyPolicy: null;
  ExternalIds: null;
  IsRequiredFieldsFilledOnce: null;
  ConsentProfile: null;
  PIN: null;
  RegistrationData: null;
  ID: string;
  Provider: string;
  Prefix: null;
  FirstName: string;
  MiddleName: null;
  LastName: string;
  Suffix: null;
  FullName: string;
  NickName: null;
  ProfileName: null;
  BirthDate: null;
  Gender: string;
  Website: null;
  Email: Email[] | null;
  Country: null;
  ThumbnailImageUrl: string;
  ImageUrl: string;
  Favicon: null;
  ProfileUrl: string;
  HomeTown: null;
  State: null;
  City: string;
  Industry: null;
  About: null;
  TimeZone: null;
  LocalLanguage: string;
  CoverPhoto: null;
  TagLine: null;
  Language: string;
  Verified: string;
  UpdatedTime: null;
  Positions: null;
  Educations: null;
  PhoneNumbers: null;
  IMAccounts: null;
  Addresses: null;
  MainAddress: null;
  Created: null;
  CreatedDate: Date;
  ModifiedDate: Date;
  ProfileModifiedDate: null;
  LocalCity: null;
  ProfileCity: string;
  LocalCountry: string;
  ProfileCountry: null;
  FirstLogin: boolean;
  IsProtected: boolean;
  RelationshipStatus: null;
  Quota: null;
  Quote: null;
  InterestedIn: null;
  Interests: null;
  Religion: null;
  Political: null;
  Sports: null;
  InspirationalPeople: null;
  HttpsImageUrl: string;
  FollowersCount: number;
  FriendsCount: number;
  IsGeoEnabled: null;
  TotalStatusesCount: number;
  Associations: null;
  NumRecommenders: number;
  Honors: null;
  Awards: null;
  Skills: null;
  CurrentStatus: null;
  Certifications: null;
  Courses: null;
  Volunteer: null;
  RecommendationsReceived: null;
  Languages: null;
  Projects: null;
  Games: null;
  Family: null;
  TeleVisionShow: null;
  MutualFriends: null;
  Movies: null;
  Books: null;
  AgeRange: null;
  PublicRepository: null;
  Hireable: boolean;
  RepositoryUrl: null;
  Age: null;
  Patents: null;
  FavoriteThings: null;
  ProfessionalHeadline: null;
  ProviderAccessCredential: ProviderAccessCredential;
  RelatedProfileViews: null;
  KloutScore: null;
  LRUserID: null;
  PlacesLived: null;
  Publications: null;
  JobBookmarks: null;
  Suggestions: null;
  Badges: null;
  MemberUrlResources: null;
  TotalPrivateRepository: number;
  Currency: null;
  StarredUrl: null;
  GistsUrl: null;
  PublicGists: number;
  PrivateGists: number;
  Subscription: null;
  Company: null;
  GravatarImageUrl: string;
  ProfileImageUrls: IProfileProfileImageUrls;
  WebProfiles: null;
  PinsCount: number;
  BoardsCount: number;
  LikesCount: number;
  SignupDate: string;
  LastLoginDate: string;
  PreviousUids: null;
}

export interface CustomFields {}

export interface Email {
  Type: string;
  Value: string;
}

export interface Identity {
  ID: string;
  Provider: string;
  Prefix: null;
  FirstName: string;
  MiddleName: null;
  LastName: string;
  Suffix: null;
  FullName: string;
  NickName: null;
  ProfileName: null;
  BirthDate: null;
  Gender: string;
  Website: null;
  Email: Email[];
  Country: null;
  ThumbnailImageUrl: string;
  ImageUrl: string;
  Favicon: null;
  ProfileUrl: string;
  HomeTown: null;
  State: null;
  City: string;
  Industry: null;
  About: null;
  TimeZone: null;
  LocalLanguage: string;
  CoverPhoto: null;
  TagLine: null;
  Language: string;
  Verified: string;
  UpdatedTime: null;
  Positions: null;
  Educations: null;
  PhoneNumbers: null;
  IMAccounts: null;
  Addresses: null;
  MainAddress: null;
  Created: null;
  CreatedDate: Date;
  ModifiedDate: Date;
  ProfileModifiedDate: Date;
  LocalCity: string;
  ProfileCity: string;
  LocalCountry: string;
  ProfileCountry: null;
  FirstLogin: boolean;
  IsProtected: boolean;
  RelationshipStatus: null;
  Quota: null;
  Quote: null;
  InterestedIn: null;
  Interests: null;
  Religion: null;
  Political: null;
  Sports: null;
  InspirationalPeople: null;
  HttpsImageUrl: string;
  FollowersCount: number;
  FriendsCount: number;
  IsGeoEnabled: null;
  TotalStatusesCount: number;
  Associations: null;
  NumRecommenders: number;
  Honors: null;
  Awards: null;
  Skills: null;
  CurrentStatus: null;
  Certifications: null;
  Courses: null;
  Volunteer: null;
  RecommendationsReceived: null;
  Languages: null;
  Projects: null;
  Games: null;
  Family: null;
  TeleVisionShow: null;
  MutualFriends: null;
  Movies: null;
  Books: null;
  AgeRange: null;
  PublicRepository: null;
  Hireable: boolean;
  RepositoryUrl: null;
  Age: null;
  Patents: null;
  FavoriteThings: null;
  ProfessionalHeadline: null;
  ProviderAccessCredential: ProviderAccessCredential;
  RelatedProfileViews: null;
  KloutScore: null;
  LRUserID: null;
  PlacesLived: null;
  Publications: null;
  JobBookmarks: null;
  Suggestions: null;
  Badges: null;
  MemberUrlResources: null;
  TotalPrivateRepository: number;
  Currency: null;
  StarredUrl: null;
  GistsUrl: null;
  PublicGists: number;
  PrivateGists: number;
  Subscription: null;
  Company: null;
  GravatarImageUrl: string;
  ProfileImageUrls: IdentityProfileImageUrls;
  WebProfiles: null;
  PinsCount: number;
  BoardsCount: number;
  LikesCount: number;
  SignupDate: Date;
  LastLoginDate: Date;
  PreviousUids: null;
}

export interface IdentityProfileImageUrls {
  Profile: string;
}

export interface ProviderAccessCredential {
  AccessToken: string;
  TokenSecret: null;
}

export interface IProfileProfileImageUrls {
  "Image Url": string;
  "ThumbnailImage Url": string;
}

export interface IGetProfile extends IProfile, ILRError {}

export interface IGetProfileArgs {
  token: string;
  skip_fetch?: boolean;
}
export const getProfileAction = createAction<IGetProfile, IGetProfileArgs>(
  userConst.FETCH_PROFILE
);

export interface IVerifyIsPosted {
  IsPosted?: boolean;
}
export interface IVerifyProfile extends IVerifyIsPosted, ILRError {}
export interface IVerifyProfileArgs {
  otp?: string;
  email?: string;
}
export const verifyProfileAction = createAction<
  IVerifyProfile,
  IVerifyProfileArgs
>(userConst.VERIFY_PROFILE);

export interface IRegisterProfileData {
  Profile: IProfile;
  access_token: string;
  expires_in: string;
  refresh_token: string;
}
export interface IRegisterProfile extends ILRError {
  IsPosted?: boolean;
  Data?: IRegisterProfileData;
}
export interface IRegisterProfileArgs {
  email: any[];
  password: string;
  firstName: string;
  company: string;
  customFields: IRegisterProfileCustomFieldsArgs;
}
export interface IRegisterProfileCustomFieldsArgs {
  company_role: string;
}
export const registerProfileAction = createAction<
  IRegisterProfile,
  IRegisterProfileArgs
>(userConst.REGISTER_PROFILE);

export interface IInvalidateToken extends IAPIError {}
export interface IInvalidateTokenArgs {
  token: string;
}
export const invalidateTokenAction = createAction<
  IInvalidateToken,
  IInvalidateTokenArgs
>(userConst.INVALIDATE_TOKEN);

export interface IResendVerificationEmail extends IVerifyIsPosted, ILRError {}
export interface IResendVerificationEmailArgs {
  email?: string;
}
export const resendVerificationEmailAction = createAction<
  IResendVerificationEmail,
  IResendVerificationEmailArgs
>(userConst.RESEND_VERIFICATION_EMAIL);

//
export interface IAddEmail extends IVerifyIsPosted, ILRError {}
export interface IAddEmailArgs {
  email?: string;
  type?: string;
  token?: string;
}
export const addEmailAction = createAction<IAddEmail, IAddEmailArgs>(
  userConst.ADD_EMAIL
);

// Reauth
export interface IReauth extends ILRError {
  SecondFactorValidationToken?: string;
  ExpireIn?: string;
}
export interface IReauthArgs {
  password?: string;
  token: string;
}
export const reauthAccountAction = createAction<IReauth, IReauthArgs>(
  userConst.REAUTH_ACCOUNT
);

// Update Profile
export interface IUpdateProfile extends ILRError {
  IsPosted?: boolean;
}
export interface IUpdateProfileArgs {
  token: string;
  Payload: IUpdateProfilePayload;
}
export interface IUpdateProfilePayload {
  CustomFields?: CustomFields;
}
export interface CustomFields {
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_term?: string;
  adgroup?: string;
  network?: string;
  keyword?: string;
  creative?: string;
  gclid?: string;
  gcl?: string;
}
export const updateProfileAction = createAction<
  IUpdateProfile,
  IUpdateProfileArgs
>(userConst.UPDATE_PROFILE);

// Track Signup
export interface ITrackSignup extends ISideEffects {}
export interface ITrackSignupArgs {
  name: string;
  email: string;
  utm_source: string;
}
export const trackSignupAction = createAction<ITrackSignup, ITrackSignupArgs>(
  userConst.TRACK_SIGNUP
);

export interface IUsers {
  email: string;
  _id: string;
  created_at: string
}

export interface IGetUsers extends IAPIError{
  data?: IUsers[];
}

export interface IGetUserArgs extends ProjectArgs{
  token: string
}

export const getAllUsersAction = createAction<
IGetUsers,
IGetUserArgs
>(userConst.GET_USERS);