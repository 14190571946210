import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { ReferralServiceHttp } from "../../services/http";
import {
  IGetReferralStatsByEmail,
  IGetReferralStatsByEmailArgs,
  getReferralStatsByEmailAction,
  ICreateReferralInviteArgs,
  createReferralInviteAction,
  referralConst
} from "../actions/referral";

function* getReferralStatsByEmailHandler(args: IGetReferralStatsByEmailArgs) {
  try {
    yield put(getReferralStatsByEmailAction.loading());

    const resp: IGetReferralStatsByEmail = yield ReferralServiceHttp.get(
      `/invite/statsbyemail/${args.email}`
    );

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getReferralStatsByEmailAction.success(resp));
  } catch (e) {
    yield put(getReferralStatsByEmailAction.error(e.message));
  }
}

export function* watchGetReferralStatsByEmail() {
  while (true) {
    const { payload }: IAction<IGetReferralStatsByEmailArgs> = yield take(
      referralConst.GET_STATS_BY_EMAIL
    );
    yield call(getReferralStatsByEmailHandler, payload);
  }
}

function* createReferralInviteHandler(args: ICreateReferralInviteArgs) {
  try {
    yield put(createReferralInviteAction.loading());

    const resp: IGetReferralStatsByEmail = yield ReferralServiceHttp.post(
      "/invite",
      {},
      JSON.stringify(args)
    );

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(createReferralInviteAction.success(resp));
  } catch (e) {
    yield put(createReferralInviteAction.error(e.message));
  }
}

export function* watchCreateReferralInvite() {
  while (true) {
    const { payload }: IAction<ICreateReferralInviteArgs> = yield take(
      referralConst.CREATE_INVITE
    );
    yield call(createReferralInviteHandler, payload);
  }
}
