import { combineReducers } from "redux";
import { IAction } from "../../schema";
import {
  IDeleteSuppressionBounce,
  IGetSuppressionBounces,
  IGetSuppressionSpamReports,
  IGetSuppressionUnsubscribeReports,
  suppressionConst
} from "../actions/suppression";

const getSuppressionBouncesReducer = (
  state: IGetSuppressionBounces = {},
  action: IAction<IGetSuppressionBounces>
): IGetSuppressionBounces => {
  const { type, payload } = action;

  if (type === suppressionConst.GET_SUPPRESSION_BOUNCE_LIST) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const getSuppressionSpamsReducer = (
  state: IGetSuppressionSpamReports = {},
  action: IAction<IGetSuppressionSpamReports>
): IGetSuppressionSpamReports => {
  const { type, payload } = action;

  if (type === suppressionConst.GET_SUPPRESSION_SPAM_LIST) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const getSuppressionUnsubscribesReducer = (
  state: IGetSuppressionUnsubscribeReports = {},
  action: IAction<IGetSuppressionUnsubscribeReports>
): IGetSuppressionUnsubscribeReports => {
  const { type, payload } = action;

  if (type === suppressionConst.GET_SUPPRESSION_UNSUBSCRIBE_LIST) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const removeSuppressionBounceReducer = (
  state: IDeleteSuppressionBounce = {},
  action: IAction<IDeleteSuppressionBounce>
): IDeleteSuppressionBounce => {
  const { type, payload } = action;

  if (type === suppressionConst.DELETE_SUPPRESSION_BOUNCE) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootSuppressionReducer = combineReducers({
  Bounces: getSuppressionBouncesReducer,
  Spams: getSuppressionSpamsReducer,
  Unsubscribes: getSuppressionUnsubscribesReducer,
  RemoveSuppressionBounce: removeSuppressionBounceReducer
});

export interface IRSuppression {
  Bounces: IGetSuppressionBounces;
  Spams: IGetSuppressionSpamReports;
  Unsubscribes: IGetSuppressionUnsubscribeReports;
  RemoveSuppressionBounce: IDeleteSuppressionBounce;
}
