import { createAction, ProjectArgs } from ".";
import { IAPIError } from "../../schema";
import { IObject } from "../schema";

export const partnerDomainConst = {
  GET_PARTNER_DOMAIN: "GET_PARTNER_DOMAIN",
  ADD_PARTNER_DOMAIN: "ADD_PARTNER_DOMAIN",
  VERIFY_PARTNER_DOMAIN: "VERIFY_PARTNER_DOMAIN"
};

export interface IPartnerDomain {
  domain?: IObject;
  form_options?: IObject;
}

export interface IGetPartnerDomain extends IPartnerDomain, IAPIError {}

export interface IGetPartnerDomainArgs extends ProjectArgs {
  token: string;
}

export const getPartnerDomainAction = createAction<
  IGetPartnerDomain,
  IGetPartnerDomainArgs
>(partnerDomainConst.GET_PARTNER_DOMAIN);

export interface IAddPartnerDomain extends IAPIError {
  data?: IObject;
}
export interface IAddPartnerDomainArgs {
  token: string;
  name: string;
  dns_provider?: string | undefined;
  api_key?: string;
  access_key?: string;
  access_secret?: string;
  secret_token?: string;
  zone_id?: string;
  service_key?: File;
  project_id?: string;
  email?: string;
}

export const addPartnerDomainAction = createAction<
  IAddPartnerDomain,
  IAddPartnerDomainArgs
>(partnerDomainConst.ADD_PARTNER_DOMAIN);

export interface IDomainList {
  key: string;
  text: string;
  value: string;
}

export interface IDomain {
  name: string;
  fields: Array<IObject>;
}

// Verify Partner Domain
export interface IVerifyPartnerDomain extends IAPIError {
  success?: boolean;
  dns_error?: string;
}
export interface IVerifyPartnerDomainArgs {
  token: string;
}
export const verifyPartnerDomainAction = createAction<
  IVerifyPartnerDomain,
  IVerifyPartnerDomainArgs
>(partnerDomainConst.VERIFY_PARTNER_DOMAIN);
