import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { AdminHttp, AdminMultipartDataHttp } from "../../services/http";

import {
  addPartnerDomainAction,
  getPartnerDomainAction,
  IAddPartnerDomain,
  IAddPartnerDomainArgs,
  IGetPartnerDomain,
  IGetPartnerDomainArgs,
  IVerifyPartnerDomain,
  IVerifyPartnerDomainArgs,
  partnerDomainConst,
  verifyPartnerDomainAction
} from "../actions/domain";

function* getPartnerDomainHandler(args: IGetPartnerDomainArgs) {
  try {
    yield put(getPartnerDomainAction.loading());

    const resp: IGetPartnerDomain = yield AdminHttp({
      token: args.token
    }).get("/domain");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getPartnerDomainAction.success(resp));
  } catch (e) {
    yield put(getPartnerDomainAction.error(e.message));
  }
}

export function* watchGetPartnerDomain() {
  while (true) {
    const { payload }: IAction<IGetPartnerDomainArgs> = yield take(
      partnerDomainConst.GET_PARTNER_DOMAIN
    );
    yield call(getPartnerDomainHandler, payload);
  }
}

function* addPartnerDomainHandler(args: IAddPartnerDomainArgs) {
  const formData = new FormData();
  for (const name in args) {
    formData.set(name, (args as any)[name]);
  }
  try {
    yield put(addPartnerDomainAction.loading());

    const resp: IAddPartnerDomain = yield args.dns_provider === "googledns"
      ? AdminMultipartDataHttp({
          token: args.token
        }).post(
          "/domain",
          {},
          args.dns_provider === "googledns"
            ? formData
            : JSON.stringify({
                name: args.name,
                dns_provider: args.dns_provider,
                api_key: args.api_key,
                access_key: args.access_key,
                access_secret: args.access_secret,
                project_id: args.project_id,
                service_key: args.service_key,
                secret_token: args.secret_token,
                zone_id: args.zone_id
              })
        )
      : AdminHttp({
          token: args.token
        }).post(
          "/domain",
          {},
          JSON.stringify({
            name: args.name,
            dns_provider: args.dns_provider,
            api_key: args.api_key,
            access_key: args.access_key,
            access_secret: args.access_secret,
            project_id: args.project_id,
            service_key: args.service_key,
            secret_token: args.secret_token,
            zone_id: args.zone_id,
            email: args.email
          })
        );

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(addPartnerDomainAction.success(resp));
  } catch (e) {
    yield put(addPartnerDomainAction.error(e.message));
  }
}

export function* watchAddPartnerDomain() {
  while (true) {
    const { payload }: IAction<IAddPartnerDomainArgs> = yield take(
      partnerDomainConst.ADD_PARTNER_DOMAIN
    );
    yield call(addPartnerDomainHandler, payload);
  }
}

function* verifyPartnerDomainHandler(args: IVerifyPartnerDomainArgs) {
  try {
    yield put(verifyPartnerDomainAction.loading());

    const resp: IVerifyPartnerDomain = yield AdminHttp({
      token: args.token
    }).get("/domain/dns");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(verifyPartnerDomainAction.success(resp));
  } catch (e) {
    yield put(verifyPartnerDomainAction.error(e.message));
  }
}

export function* watchVerifyPartnerDomain() {
  while (true) {
    const { payload }: IAction<IVerifyPartnerDomainArgs> = yield take(
      partnerDomainConst.VERIFY_PARTNER_DOMAIN
    );
    yield call(verifyPartnerDomainHandler, payload);
  }
}
