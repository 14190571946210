import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { ProjectInsightsHttp } from "../../services/http";
import {
  IGetLogsArgs,
  getLogsAction,
  IGetLogs,
  logsConst,
  IGetLogsByDateArgs,
  getLogsByDateAction,
  IGetLogsByEmailArgs,
  getLogsByEmailAction,
  IGetLogsByEmail
} from "../actions/logs";

function* getLogsHandler(args: IGetLogsArgs) {
  try {
    let url = "/logs?";
    if (args.next_cursor) {
      url += `next_cursor=${args.next_cursor}&`;
    } else if (args.prev_cursor) {
      url += `prev_cursor=${args.prev_cursor}&`;
    }
    console.log(JSON.stringify(args));
    if (args.status) {
      url += `status=${args.status}`;
    }

    yield put(getLogsAction.loading());

    const resp: IGetLogs = yield ProjectInsightsHttp({
      token: args.token,
      projectId: args.projectId
    }).get(url);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getLogsAction.success(resp));
  } catch (e) {
    yield put(getLogsAction.error(e.message));
  }
}

export function* watchGetLogs() {
  while (true) {
    const { payload }: IAction<IGetLogsArgs> = yield take(logsConst.GET_LOGS);
    yield call(getLogsHandler, payload);
  }
}

function* getLogsByDateHandler(args: IGetLogsByDateArgs) {
  try {
    let url = `/logs_by_date?from=${args.from}&to=${args.to}&`;
    if (args.page) {
      url += `page=${args.page}&`;
    }
    if (args.status) {
      url += `status=${args.status}`;
    }

    yield put(getLogsByDateAction.loading());

    const resp: IGetLogs = yield ProjectInsightsHttp({
      token: args.token,
      projectId: args.projectId
    }).get(url);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getLogsByDateAction.success(resp));
  } catch (e) {
    yield put(getLogsByDateAction.error(e.message));
  }
}

export function* watchGetLogsByDate() {
  while (true) {
    const { payload }: IAction<IGetLogsByDateArgs> = yield take(
      logsConst.GET_LOGS_BY_DATE
    );
    yield call(getLogsByDateHandler, payload);
  }
}

function* getLogsByEmailHandler(args: IGetLogsByEmailArgs) {
  try {
    let url = `/logs_by_email?`;
    if (args.from) {
      url += `from=${args.from}&`;
    }
    if (args.rcpt_to) {
      url += `rcpt_to=${args.rcpt_to}&`;
    }
    if (args.from_domain) {
      url += `from_domain=${args.from_domain}&`;
    }
    if (args.page) {
      url += `page=${args.page}&`;
    }
    if (args.status) {
      url += `status=${args.status}`;
    }

    yield put(getLogsByEmailAction.loading());

    const resp: IGetLogsByEmail = yield ProjectInsightsHttp({
      token: args.token,
      projectId: args.projectId
    }).get(url);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getLogsByEmailAction.success(resp));
  } catch (e) {
    yield put(getLogsByEmailAction.error(e.message));
  }
}

export function* watchGetLogsByEmail() {
  while (true) {
    const { payload }: IAction<IGetLogsByEmailArgs> = yield take(
      logsConst.GET_LOGS_BY_EMAIL
    );
    yield call(getLogsByEmailHandler, payload);
  }
}
