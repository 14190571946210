import { IDomainList } from "src/redux/actions/domain";
import { ISenderDomains } from "src/redux/actions/settings";

export const isAnySenderDomainVerified = (
  verificationSettings: Array<ISenderDomains> | undefined
) => {
  if (verificationSettings) {
    for (let i = 0; i < verificationSettings.length; i++) {
      const element = verificationSettings[i];
      if (
        element.domain &&
        element.spf_verified &&
        element.dkim_verified_s1 &&
        element.dkim_verified_s2
      ) {
        return true;
      }
    }
  }
  return false;
};

export const isAnySenderDomainVerifiedAndActivated = (
  verificationSettings: Array<ISenderDomains> | undefined
) => {
  if (verificationSettings) {
    for (let i = 0; i < verificationSettings.length; i++) {
      const element = verificationSettings[i];
      if (
        element.domain &&
        element.spf_verified &&
        element.dkim_verified_s1 &&
        element.dkim_verified_s2 &&
        element.allow_send_email
      ) {
        return true;
      }
    }
  }
  return false;
};

export const isAnySenderDomainActivated = (
  verificationSettings: Array<ISenderDomains> | undefined
) => {
  if (verificationSettings) {
    for (let i = 0; i < verificationSettings.length; i++) {
      const element = verificationSettings[i];
      if (
        element.domain &&
        element.spf_verified &&
        element.dkim_verified_s1 &&
        element.dkim_verified_s2 &&
        element.allow_send_email
      ) {
        return true;
      }
    }
  }
  return false;
};

export const getVerifiedSenderDomains = (
  verificationSettings: Array<ISenderDomains> | undefined
) => {
  if (verificationSettings) {
    let resp: Array<ISenderDomains> = [];
    for (let i = 0; i < verificationSettings.length; i++) {
      const element = verificationSettings[i];
      if (
        element.domain &&
        element.spf_verified &&
        element.dkim_verified_s1 &&
        element.dkim_verified_s2
      ) {
        resp.push(element);
      }
    }
    return resp;
  }
  return null;
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isNumeric = (num: any) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num as number);

  export const getDomains = (
    domains: IDomainList | undefined
  ) => {
    if (domains) {
      let resp: Array<IDomainList> = [];
      for (let i = 0; i < Object.keys(domains).length; i++) {
        const element = {
          key: Object.keys(domains)[i],
          text: Object.values(domains)[i].name,
          value: Object.keys(domains)[i]
        };
        if (
          element.key && 
          element.text && 
          element.value 
        ) {
          resp.push(element);
        }
      }
      return resp;
    }
    return null;
  };