import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";

import { RootSchema } from "../../redux/reducers";
import { getCookie } from "../../utils/urls";

import {
  ISendTestEmailArgs,
  sendTestEmailAction,
  IGetSenderVerificationSettings,
  ISendTestEmail,
  IGetSenderVerificationArgs,
  getVerificationSettingsAction
} from "../../redux/actions/settings";

import {
  Card,
  Button,
  Input,
  Dropdown,
  Form,
  Checkbox
} from "semantic-ui-react";
import { getDomains } from "src/utils/util";
import { IAppState } from "src/redux/actions/default";
import "./domainWhitelisting.scss";
import {
  addPartnerDomainAction,
  getPartnerDomainAction,
  IAddPartnerDomain,
  IAddPartnerDomainArgs,
  IGetPartnerDomain,
  IGetPartnerDomainArgs,
  IVerifyPartnerDomain,
  IVerifyPartnerDomainArgs,
  verifyPartnerDomainAction
} from "src/redux/actions/domain";
import { IObject } from "src/schema";

export interface TestEmailProps extends RouteComponentProps {
  appState: IAppState;
  verificationSettings: IGetSenderVerificationSettings;
  testEmail: ISendTestEmail;
  partnerDomain: IGetPartnerDomain;
  addPartnerDomain: IAddPartnerDomain;
  verifyPartnerDomain: IVerifyPartnerDomain;

  getPartnerDomainAction: (args: IGetPartnerDomainArgs) => void;
  addPartnerDomainAction: (args: IAddPartnerDomainArgs) => void;
  verifyPartnerDomainAction: (args: IVerifyPartnerDomainArgs) => void;
}

export interface TestEmailState {
  auth: string;
  loading?: boolean;
  rcptTo: string;
  domain: string;
  senderDomain?: string;
  selectedDNS: string;
  inputObject: IObject;
  isDNSChecked: boolean;
}

class TestEmail extends React.Component<TestEmailProps, TestEmailState> {
  constructor(props: TestEmailProps) {
    super(props);
    this.state = {
      auth: getCookie("accessToken") || "",
      rcptTo: "",
      domain: "",
      selectedDNS: "",
      inputObject: {},
      isDNSChecked: false
    };
  }

  componentDidMount() {
    document.title = "Domain Whitelisting | Mailazy";
    this.props.getPartnerDomainAction({ token: this.state.auth });
  }

  componentDidUpdate(pProps: TestEmailProps) {
    if (pProps.partnerDomain.state === "loading") {
      if (this.props.partnerDomain.state === "success") {
        if (this.props.partnerDomain.domain?.name) {
          this.setState({ domain: this.props.partnerDomain.domain?.name });
        }
        if (this.props.partnerDomain.domain?.dns_provider) {
          this.setState({ isDNSChecked: true });
        }
      }
    }

    if (pProps.addPartnerDomain.state === "loading") {
      if (this.props.partnerDomain.state === "success") {
        this.props.getPartnerDomainAction({ token: this.state.auth });
      } else if (this.props.partnerDomain.state === "error") {
        toast.error(this.props.partnerDomain.error);
      }
    }

    if (pProps.verifyPartnerDomain.state === "loading") {
      const { verifyPartnerDomain } = this.props;
      if (verifyPartnerDomain.state === "success") {
        if (verifyPartnerDomain.success) {
          toast.success("Verified Successfully!");
          this.props.getPartnerDomainAction({ token: this.state.auth });
        } else {
          toast.error(verifyPartnerDomain.dns_error);
        }
      } else if (verifyPartnerDomain.state === "error") {
        toast.error(verifyPartnerDomain.error);
      }
    }
  }

  handleCheckBox = (event: any, data: any) => {
    this.setState({ isDNSChecked: data.checked });
  };
  handleInputChange = (e: { target: { value: any } }) =>
    this.setState({ rcptTo: e.target.value });

  handleDropDownSelect = (event: any, data: any) => {
    event.preventDefault();
    this.setState({ selectedDNS: event.target.textContent });
    const dns: any = Object.values(
      this.props.partnerDomain.form_options?.dns_providers
    ).find((dns: any) => {
      return dns.name === event.target.textContent;
    });
    if (!dns || !dns.fields) {
      return;
    }
    const inputObject = dns.fields.reduce((accumulator: any, value: any) => {
      return { ...accumulator, [value.name]: "" };
    }, {});
    this.setState({ inputObject: inputObject });
  };
  submitDomain = (dns: any) => {
    console.log(this.state.inputObject);
    const inputValues = !this.state.isDNSChecked
      ? {
          ...this.state.inputObject,
          name: this.state.domain,
          dns_provider: Object.keys(
            this.props.partnerDomain.form_options?.dns_providers
          ).find((dns: any) => {
            return (
              this.props.partnerDomain.form_options?.dns_providers[dns]
                ?.name === this.state.selectedDNS
            );
          }),
          token: this.state.auth
        }
      : { name: this.state.domain, token: this.state.auth };
    this.props.addPartnerDomainAction(inputValues);

    toast.success(
      dns ? "configuration added successfully" : "Domain updated successfully"
    );
  };

  handleVerifyDomain = () => {
    this.props.verifyPartnerDomainAction({ token: this.state.auth });
  };

  render() {
    let dnsProviderList;
    console.log(this.state.domain);
    if (this.props.partnerDomain.state === "success") {
      dnsProviderList = getDomains(
        this.props.partnerDomain.form_options?.dns_providers
      );
    }
    return (
      <div className="page-content testemail">
        <section>
          <div className="content">
            <div className="container">
              <div className="page-title d-flex align-items-center justify-content-start pb-3 mb-4">
                <div className="title">
                  <h2>Domain Whitelisting</h2>
                </div>
              </div>

              <Card className="w-100">
                <Card.Content>
                  <h6>Enter a domain:</h6>
                  <Input
                    className="mt-3 acc-input"
                    placeholder="Enter Domain"
                    value={this.state.domain}
                    onChange={e => {
                      this.setState({ domain: e.target.value });
                    }}
                  />

                  {this.props.partnerDomain.domain?.dns_provider ? (
                    <Checkbox
                      className="ml-3 mt-3 acc-input"
                      checked={this.state.isDNSChecked}
                      onChange={(ev, data) => {
                        this.handleCheckBox(ev, data);
                      }}
                      label="Use already configured DNS provider"
                    />
                  ) : null}
                </Card.Content>
                <Card.Content>
                  {!this.state.isDNSChecked ? (
                    <>
                      {dnsProviderList ? (
                        <Dropdown
                          placeholder="DNS Provider"
                          className="mt-3 acc-input"
                          width={2}
                          selection
                          onChange={this.handleDropDownSelect}
                          options={dnsProviderList}
                        />
                      ) : null}

                      {this.props.partnerDomain.state === "success" &&
                        Object.values(
                          this.props.partnerDomain.form_options?.dns_providers
                        ).map((dns: any) => {
                          return dns.name === this.state.selectedDNS ? (
                            <Card.Content>
                              <div className="key-container">
                                <Form>
                                  {dns.fields.map((field: any) => {
                                    return (
                                      <>
                                        <h6>{field.label}</h6>
                                        <Form.Input
                                          className="mt-3 acc-input"
                                          placeholder={field.name}
                                          name={field.name}
                                          type={field.type}
                                          onChange={e => {
                                            // if(e.target.type === 'file')console.log(e.target.files[0])
                                            this.setState({
                                              inputObject: {
                                                ...this.state.inputObject,
                                                [e.target.name]:
                                                  e.target.type === "file" &&
                                                  e.target.files
                                                    ? e.target.files[0]
                                                    : e.target.value
                                              }
                                            });
                                          }}
                                        />
                                      </>
                                    );
                                  })}
                                  <br />
                                  <Button
                                    primary
                                    size="tiny"
                                    loading={
                                      this.props.addPartnerDomain.state ===
                                      "loading"
                                    }
                                    color="blue"
                                    className="mt-3"
                                    onClick={() => this.submitDomain(dns)}
                                  >
                                    Add Domain
                                  </Button>
                                </Form>
                              </div>
                            </Card.Content>
                          ) : null;
                        })}
                    </>
                  ) : this.props.partnerDomain.domain &&
                    this.props.partnerDomain.domain["is_verified"] === false ? (
                    <Button
                      loading={
                        this.props.verifyPartnerDomain.state === "loading"
                      }
                      size="tiny"
                      color="grey"
                      className="mt-3 acc-input"
                      onClick={this.handleVerifyDomain}
                    >
                      Verify Domain
                    </Button>
                  ) : (
                    <Button
                      primary
                      loading={this.props.addPartnerDomain.state === "loading"}
                      size="tiny"
                      color="blue"
                      className="mt-3 acc-input"
                      onClick={() => this.submitDomain(null)}
                    >
                      Update Domain
                    </Button>
                  )}
                </Card.Content>
              </Card>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapState = (state: RootSchema) => {
  return {
    appState: state.default.appState,
    verificationSettings: state.settings.SenderVerificationSettings,
    testEmail: state.settings.SendTestEmail,
    partnerDomain: state.partnerDomain.PartnerDomain,
    addPartnerDomain: state.partnerDomain.AddPartnerDomain,
    verifyPartnerDomain: state.partnerDomain.VerifyPartnerDomain
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    testEmailAction: (args: ISendTestEmailArgs) =>
      dispatch(sendTestEmailAction.dispatch(args)),
    getVerificationSettingsAction: (args: IGetSenderVerificationArgs) =>
      dispatch(getVerificationSettingsAction.dispatch(args)),

    getPartnerDomainAction: (args: IGetPartnerDomainArgs) =>
      dispatch(getPartnerDomainAction.dispatch(args)),
    addPartnerDomainAction: (args: IAddPartnerDomainArgs) =>
      dispatch(addPartnerDomainAction.dispatch(args)),
    verifyPartnerDomainAction: (args: IVerifyPartnerDomainArgs) =>
      dispatch(verifyPartnerDomainAction.dispatch(args))
  };
};

export default connect(mapState, mapDispatch)(withRouter(TestEmail));
