import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
  getAllUsersAction,
  IGetProfile,
  IGetUserArgs,
  IGetUsers
} from "../../redux/actions/user";
import { RootSchema } from "../../redux/reducers";
import { getCookie } from "../../utils/urls";
import { Placeholder, Input, Table } from "semantic-ui-react";
import {
  generateAccessKeyAction,
  getAccessKeysAction,
  IGenerateAccessKey,
  IGetAccessKeys,
  IGenerateAccessKeyArgs,
  IGetAccessKeysArgs,
  IRegenerateAccessSecretArgs,
  regenerateAccessSecretAction,
  IRegenerateAccessSecret,
  IRemoveAccessKey,
  IRemoveAccessKeyArgs,
  removeAccessKeyAction
} from "../../redux/actions/access_keys";
import "./users.scss";
import copy from "clipboard-copy";
import { IAppState } from "src/redux/actions/default";

export interface UsersProps {
  appState: IAppState;
  user: IGetProfile;
  generateAccessKey: IGenerateAccessKey;
  regenerateAccessSecret: IRegenerateAccessSecret;
  accessKeys: IGetAccessKeys;
  removeAccessKey: IRemoveAccessKey;
  users: IGetUsers;
  // generateAccessKeyAction: (args: IGenerateAccessKeyArgs) => void;
  // regenerateAccessSecretAction: (args: IRegenerateAccessSecretArgs) => void;
  // getAccessKeysAction: (args: IGetAccessKeysArgs) => void;
  // removeAccessKeyAction: (args: IRemoveAccessKeyArgs) => void;
  getAllUsersAction: (args: IGetUserArgs) => void;
}
export interface UsersState {
  token: string;
  name?: string;
  key?: string;
  secret?: string;
  openConfirm?: boolean;
  openAddModal?: boolean;
  openAddModalShowGeneratedKey?: boolean;
  openConfirmModalShowGeneratedKey?: boolean;
  hasCopiedKey?: boolean;
  hasCopiedSecret?: boolean;
  openRemoveModal?: boolean;
}

class Users extends React.Component<UsersProps, UsersState> {
  inputNameRef: React.RefObject<Input>;
  constructor(props: UsersProps) {
    super(props);
    this.state = {
      token: getCookie("accessToken") || ""
    };
    this.inputNameRef = React.createRef<Input>();
  }

  componentDidMount() {
    document.title = "Users | Mailazy";
    this.__getUsers();
  }

  componentDidUpdate(userProps: UsersProps) {
    if (userProps.users.state === "loading") {
      const { users } = this.props;
      if (users.state === "success") {
      }
    }
  }

  private __getUsers = () => {
    console.log("run");
    this.props.getAllUsersAction({
      token: this.state.token
    });
  };

  handleNameInputChange = (e: { target: { value: any } }) =>
    this.setState({ name: e.target.value });

  showConfirm = (key: string | undefined) => {
    this.setState({ openConfirm: true, key: key });
  };

  handleCancel = () => this.setState({ openConfirm: false });
  openOrCloseAddModal = (state: boolean) =>
    this.setState({ openAddModal: state });
  closeAddModelWithStateReload = () => {
    this.setState({
      openAddModal: false,
      openAddModalShowGeneratedKey: false,
      openConfirmModalShowGeneratedKey: false,
      openConfirm: false,
      hasCopiedKey: false,
      hasCopiedSecret: false,
      key: undefined,
      secret: undefined,
      name: undefined
    });
  };
  copyKeyAndMarkCopied = (content: string | undefined) => {
    if (!content) {
      return;
    }
    copy(content).then(() => {
      this.setState({ hasCopiedKey: true });
    });
  };
  copySecretAndMarkCopied = (content: string | undefined) => {
    if (!content) {
      return;
    }
    copy(content).then(() => {
      this.setState({ hasCopiedSecret: true });
    });
  };

  render() {
    const { users } = this.props;
    return (
      <>
        <div className="page-content access-key">
          <section>
            <div className="content">
              <div className="container dashboard">
                <div className="page-title d-flex align-items-center justify-content-start pb-3 mb-4">
                  <div className="title">
                    <h2>Users</h2>
                  </div>
                  {/* <Button
                    primary
                    size="tiny"
                    color="blue"
                    floated="right"
                    style={{ marginLeft: "auto" }}
                    onClick={() => this.openOrCloseAddModal(true)}
                  >
                    Create Access Key
                  </Button> */}
                </div>
                <div className="mt-3 ui cards accesskeys">
                  <Table unstackable>
                    {users.state === "loading" ? (
                      <Placeholder fluid>
                        <Placeholder.Line length="full" />
                        <Placeholder.Line length="full" />
                        <Placeholder.Line length="full" />
                        <Placeholder.Line length="full" />
                        <Placeholder.Line length="full" />
                      </Placeholder>
                    ) : users.data ? (
                      this._renderUsers()
                    ) : null}
                  </Table>
                  {/* {this._renderAddAccessKeyModal()}
                  {this._renderAccessSecretRegenerateModal()}
                  {this._renderRemoveAccessKeyModal()} */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }

  private _renderUsers = () => {
    const { users } = this.props;
    return (
      <>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="3">Email</Table.HeaderCell>
            <Table.HeaderCell width="4">Created At</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users.data?.map(user => {
            return (
              <Table.Row key={user._id}>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.created_at}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </>
    );
  };

  // private _renderAddAccessKeyModal = () => {
  //   const { generateAccessKey } = this.props;
  //   return (
  //     <Modal
  //       open={this.state.openAddModal}
  //       onClose={() => this.openOrCloseAddModal(false)}
  //       closeIcon={!this.state.openAddModalShowGeneratedKey}
  //       size="small"
  //       closeOnDocumentClick={!this.state.openAddModalShowGeneratedKey}
  //       closeOnEscape={!this.state.openAddModalShowGeneratedKey}
  //       closeOnDimmerClick={!this.state.openAddModalShowGeneratedKey}
  //       closeOnPortalMouseLeave={!this.state.openAddModalShowGeneratedKey}
  //       closeOnTriggerBlur={!this.state.openAddModalShowGeneratedKey}
  //       closeOnTriggerClick={!this.state.openAddModalShowGeneratedKey}
  //       closeOnTriggerMouseLeave={!this.state.openAddModalShowGeneratedKey}
  //     >
  //       {!this.state.openAddModalShowGeneratedKey ? (
  //         <Modal.Header>
  //           <h4>Create Access Key</h4>
  //         </Modal.Header>
  //       ) : null}
  //       <Modal.Content>
  //         {this.state.openAddModalShowGeneratedKey ? (
  //           <div style={{ textAlign: "center" }}>
  //             <h4>Access Key Created</h4>
  //             <p>Please copy the key and secret and save it somewhere safe.</p>

  //             <div className="mt-3 display-inline-block">
  //               <p className="display-initial">Key: </p>
  //               <Popup
  //                 size="mini"
  //                 inverted
  //                 content="Copied!"
  //                 open
  //                 position="right center"
  //                 disabled={!this.state.hasCopiedKey}
  //                 trigger={
  //                   <Label
  //                     size="big"
  //                     className="display-inline-block key-label"
  //                     onClick={() => {
  //                       this.copyKeyAndMarkCopied(this.state.key);
  //                     }}
  //                   >
  //                     {this.state.key}
  //                   </Label>
  //                 }
  //               />
  //             </div>
  //             <br />
  //             <div className="mt-3 display-inline-block">
  //               <p className="display-initial">Secret: </p>
  //               <Popup
  //                 size="mini"
  //                 inverted
  //                 content="Copied!"
  //                 open
  //                 position="right center"
  //                 disabled={!this.state.hasCopiedSecret}
  //                 trigger={
  //                   <Label
  //                     size="big"
  //                     className="display-inline-block key-label"
  //                     onClick={() => {
  //                       this.copySecretAndMarkCopied(this.state.secret);
  //                     }}
  //                   >
  //                     {this.state.secret}
  //                   </Label>
  //                 }
  //               />
  //             </div>

  //             <Message color="yellow" size="small">
  //               Make sure to copy your new Secret now. You won’t be able to see
  //               it again!
  //             </Message>
  //           </div>
  //         ) : (
  //           <Input
  //             ref={this.inputNameRef}
  //             value={this.state.name}
  //             disabled={generateAccessKey === "loading"}
  //             onChange={this.handleNameInputChange}
  //             fluid
  //             placeholder="Access Key Name"
  //           />
  //         )}
  //       </Modal.Content>
  //       <Modal.Actions>
  //         <Button
  //           basic
  //           color="blue"
  //           disabled={
  //             this.state.openAddModalShowGeneratedKey &&
  //             (!this.state.hasCopiedKey || !this.state.hasCopiedSecret)
  //           }
  //           loading={generateAccessKey.state === "loading"}
  //           onClick={() => {
  //             this.state.openAddModalShowGeneratedKey
  //               ? this.closeAddModelWithStateReload()
  //               : this.__generateAccessKey();
  //           }}
  //         >
  //           {this.state.openAddModalShowGeneratedKey
  //             ? "Done"
  //             : "Create and View"}
  //         </Button>
  //       </Modal.Actions>
  //     </Modal>
  //   );
  // };

  // private _renderSecret = () => {
  //   return (
  //     <Popup
  //       trigger={
  //         <Label size="medium" basic>
  //           <Icon name="lock" /> ***********************************
  //         </Label>
  //       }
  //       content="For security purposes, we hash your API secret thus we do not display the value."
  //       inverted
  //       size="small"
  //     />
  //   );
  // };

  // private _renderAccessSecretRegenerateModal = () => {
  //   const { regenerateAccessSecret } = this.props;
  //   return (
  //     <Modal
  //       open={this.state.openConfirm}
  //       onClose={this.handleCancel}
  //       closeIcon={!this.state.openConfirmModalShowGeneratedKey}
  //       size="small"
  //       closeOnDocumentClick={!this.state.openConfirmModalShowGeneratedKey}
  //       closeOnEscape={!this.state.openConfirmModalShowGeneratedKey}
  //       closeOnDimmerClick={!this.state.openConfirmModalShowGeneratedKey}
  //       closeOnPortalMouseLeave={!this.state.openConfirmModalShowGeneratedKey}
  //       closeOnTriggerBlur={!this.state.openConfirmModalShowGeneratedKey}
  //       closeOnTriggerClick={!this.state.openConfirmModalShowGeneratedKey}
  //       closeOnTriggerMouseLeave={!this.state.openConfirmModalShowGeneratedKey}
  //     >
  //       {!this.state.openConfirmModalShowGeneratedKey ? (
  //         <Header icon="warning" content="Caution" />
  //       ) : null}
  //       <Modal.Content>
  //         {!this.state.openConfirmModalShowGeneratedKey ? (
  //           <p>
  //             Are you sure you want to regenerate this access secret? This
  //             action cannot be undone and will remove access for all apps that
  //             use this key.
  //           </p>
  //         ) : (
  //           <div style={{ textAlign: "center" }}>
  //             <h4>Access Secret Regenerated</h4>
  //             <p>Please copy the secret and save it somewhere safe.</p>

  //             <div className="mt-3 display-inline-block">
  //               <p className="display-initial">Secret: </p>
  //               <Popup
  //                 size="mini"
  //                 inverted
  //                 content="Copied!"
  //                 open
  //                 position="right center"
  //                 disabled={!this.state.hasCopiedSecret}
  //                 trigger={
  //                   <Label
  //                     size="big"
  //                     className="display-inline-block key-label"
  //                     onClick={() => {
  //                       this.copySecretAndMarkCopied(this.state.secret);
  //                     }}
  //                   >
  //                     {this.state.secret}
  //                   </Label>
  //                 }
  //               />
  //             </div>

  //             <Message color="yellow" size="small">
  //               Make sure to copy your new Secret now. You won’t be able to see
  //               it again!
  //             </Message>
  //           </div>
  //         )}
  //       </Modal.Content>
  //       <Modal.Actions>
  //         {this.state.openConfirmModalShowGeneratedKey ? (
  //           <Button
  //             basic
  //             color="blue"
  //             disabled={!this.state.hasCopiedSecret}
  //             onClick={() => this.closeAddModelWithStateReload()}
  //           >
  //             Done
  //           </Button>
  //         ) : (
  //           <Button
  //             basic
  //             color="blue"
  //             onClick={this.__regenerateAccessSecret}
  //             loading={regenerateAccessSecret.state === "loading"}
  //           >
  //             <Icon name="checkmark" /> Regenerate
  //           </Button>
  //         )}
  //       </Modal.Actions>
  //     </Modal>
  //   );
  // };

  // private _renderRemoveAccessKeyModal = () => {
  //   const { removeAccessKey } = this.props;
  //   return (
  //     <Modal
  //       open={this.state.openRemoveModal}
  //       onClose={() => this.setState({ openRemoveModal: false })}
  //       closeIcon
  //       size="small"
  //     >
  //       <Header icon="warning" content="Caution" />

  //       <Modal.Content>
  //         <p>
  //           Are you sure you want to remove this access secret? This action
  //           cannot be undone and will remove access for all apps that use this
  //           key.
  //         </p>
  //       </Modal.Content>
  //       <Modal.Actions>
  //         <Button
  //           basic
  //           color="blue"
  //           onClick={this.__removeAccessKey}
  //           loading={removeAccessKey.state === "loading"}
  //         >
  //           <Icon name="trash alternate" /> Remove
  //         </Button>
  //       </Modal.Actions>
  //     </Modal>
  //   );
  // };
}

const mapState = (state: RootSchema) => {
  return {
    appState: state.default.appState,
    user: state.user.profile,
    generateAccessKey: state.accessKeys.GenerateAccessKey,
    accessKeys: state.accessKeys.AccessKeys,
    regenerateAccessSecret: state.accessKeys.RegenerateAccessSecret,
    removeAccessKey: state.accessKeys.RemoveAccessKey,
    users: state.user.getUsers
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    generateAccessKeyAction: (args: IGenerateAccessKeyArgs) =>
      dispatch(generateAccessKeyAction.dispatch(args)),
    getAccessKeysAction: (args: IGetAccessKeysArgs) =>
      dispatch(getAccessKeysAction.dispatch(args)),
    regenerateAccessSecretAction: (args: IRegenerateAccessSecretArgs) =>
      dispatch(regenerateAccessSecretAction.dispatch(args)),
    removeAccessKeyAction: (args: IRemoveAccessKeyArgs) =>
      dispatch(removeAccessKeyAction.dispatch(args)),
    getAllUsersAction: (args: IGetUserArgs) =>
      dispatch(getAllUsersAction.dispatch(args))
  };
};

export default connect(mapState, mapDispatch)(Users);
