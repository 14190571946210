import { createAction, ProjectArgs } from ".";
import { IAPIError } from "../../schema";

export const insightsConst = {
  GET_USAGE_INSIGHTS: "GET_USAGE_INSIGHTS",
  GET_FAILED_TRANSACTION_INSIGHTS: "GET_FAILED_TRANSACTION_INSIGHTS",
  GET_GRAPH_INSIGHTS: "GET_GRAPH_INSIGHTS",
};

export interface IUsageInsights {
  today_usage?: number;
  month_usage?: number;
  limit_per_day?: number;
  limit_per_month?: number;
  month_usage_by_domain?: Record<string, number>;
  today_usgae_by_domain?: Record<string, number>;
  is_pro_subscription_active?: boolean;
}

export interface IFailedTransactionInsights {
  today_soft_bounce?: number;
  month_soft_bounce?: number;
  today_hard_bounce?: number;
  month_hard_bounce?: number;
  spam_reports?: number;
}

export interface IGetUsageInsights extends IUsageInsights, IAPIError { }
export interface IGetInsightsArgs extends ProjectArgs {
  token: string;
}
export const getUsageInsightsAction = createAction<
  IGetUsageInsights,
  IGetInsightsArgs
>(insightsConst.GET_USAGE_INSIGHTS);

export interface IGetFailedTransactionInsights
  extends IFailedTransactionInsights,
  IAPIError { }
export const getFailedTransactionInsightsAction = createAction<
  IGetFailedTransactionInsights,
  IGetInsightsArgs
>(insightsConst.GET_FAILED_TRANSACTION_INSIGHTS);


export interface GraphInsights {
  t?: number;
  c?: number;
}
export interface GetGraphInsightsResp {
  insights?: Array<GraphInsights>;
  has_data?: boolean;
}

export interface IGetGraphInsights extends GetGraphInsightsResp, IAPIError { }
export const getGraphInsightsAction = createAction<IGetGraphInsights, IGetInsightsArgs>(insightsConst.GET_GRAPH_INSIGHTS);
