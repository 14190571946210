import { combineReducers } from "redux";
import { IAction } from "../../schema";
import {
  settingsConst,
  IGetSenderVerificationSettings,
  ISetSenderDomain,
  IVerifySenderDomain,
  ISendTestEmail,
  IResetSenderDomain,
  IGetSenderDomainVerificationKeys,
  ISendSenderDomainVerificationKeysEmail,
  ISetSenderIntegrationStatus,
  IGetTrackOptionsStatus,
  ISetTrackOptionsStatus,
  IAllocateDedicatedIP,
  IGetDedicatedIP
} from "../actions/settings";

const getSenderVerificationReducer = (
  state: IGetSenderVerificationSettings = {},
  action: IAction<IGetSenderVerificationSettings>
): IGetSenderVerificationSettings => {
  const { type, payload } = action;

  if (
    type === settingsConst.GET_SENDER_VERIFICATION_SETTINGS ||
    type === settingsConst.RESET_SENDER_DOMAIN
  ) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success"
        };
      default:
        return state;
    }
  }

  return state;
};

const setSenderDomainReducer = (
  state: ISetSenderDomain = {},
  action: IAction<ISetSenderDomain>
): ISetSenderDomain => {
  const { type, payload } = action;

  if (type === settingsConst.SET_SENDER_DOMAIN) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", accepted: payload.accepted };
      default:
        return state;
    }
  }

  return state;
};

const verifySenderDomainReducer = (
  state: IVerifySenderDomain = {},
  action: IAction<IVerifySenderDomain>
): IVerifySenderDomain => {
  const { type, payload } = action;

  if (type === settingsConst.VERIFY_SENDER_DOMAIN) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", verified: payload.verified };
      default:
        return state;
    }
  }

  return state;
};

const sendTestEmailReducer = (
  state: ISendTestEmail = {},
  action: IAction<ISendTestEmail>
): ISendTestEmail => {
  const { type, payload } = action;

  if (type === settingsConst.SEND_TEST_EMAIL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", sent: payload.sent };
      default:
        return state;
    }
  }

  return state;
};

const resetSenderDomainReducer = (
  state: IResetSenderDomain = {},
  action: IAction<IResetSenderDomain>
): IResetSenderDomain => {
  const { type, payload } = action;

  if (type === settingsConst.RESET_SENDER_DOMAIN) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", accepted: payload.accepted };
      default:
        return state;
    }
  }

  return state;
};

const getSenderVerificationKeysReducer = (
  state: IGetSenderDomainVerificationKeys = {},
  action: IAction<IGetSenderDomainVerificationKeys>
): IGetSenderDomainVerificationKeys => {
  const { type, payload } = action;

  if (type === settingsConst.GET_SENDER_VERIFICATION_KEYS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", dns_records: payload.dns_records };
      default:
        return state;
    }
  }

  return state;
};

const sendSenderVerificationKeysEmailReducer = (
  state: ISendSenderDomainVerificationKeysEmail = {},
  action: IAction<ISendSenderDomainVerificationKeysEmail>
): ISendSenderDomainVerificationKeysEmail => {
  const { type, payload } = action;

  if (type === settingsConst.SEND_SENDER_VERIFICATION_KEYS_EMAIL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", sent: payload.sent };
      default:
        return state;
    }
  }

  return state;
};

const setSenderIntegrationStatusReducer = (
  state: ISetSenderIntegrationStatus = {},
  action: IAction<ISetSenderIntegrationStatus>
): ISetSenderIntegrationStatus => {
  const { type, payload } = action;

  if (type === settingsConst.SEND_SENDER_VERIFICATION_KEYS_EMAIL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", posted: payload.posted };
      default:
        return state;
    }
  }

  return state;
};

const getTrackOptionsStatusReducer = (
  state: IGetTrackOptionsStatus = {},
  action: IAction<IGetTrackOptionsStatus>
): IGetTrackOptionsStatus => {
  const { type, payload } = action;

  if (type === settingsConst.GET_TRACK_OPTIONS_STATUS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, ...payload, state: "success" };
      default:
        return state;
    }
  }

  return state;
};

const setTrackOptionsStatusReducer = (
  state: ISetTrackOptionsStatus = {},
  action: IAction<ISetTrackOptionsStatus>
): ISetTrackOptionsStatus => {
  const { type, payload } = action;

  if (type === settingsConst.SET_TRACK_OPTIONS_STATUS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, ...payload, state: "success" };
      default:
        return state;
    }
  }

  return state;
};

const getDedicatedIPReducer = (
  state: IGetDedicatedIP = {},
  action: IAction<IGetDedicatedIP>
): IGetDedicatedIP => {
  const { type, payload } = action;

  if (type === settingsConst.GET_DEDICATED_IP) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, ...payload, state: "success" };
      default:
        return state;
    }
  }

  return state;
};

const postAllocateDedicatedIPReducer = (
  state: IAllocateDedicatedIP = {},
  action: IAction<IAllocateDedicatedIP>
): IAllocateDedicatedIP => {
  const { type, payload } = action;

  if (type === settingsConst.ALLOCATE_DEDICATED_IP) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, ...payload, state: "success" };
      default:
        return state;
    }
  }

  return state;
};

export const rootSettingsReducer = combineReducers({
  SenderVerificationSettings: getSenderVerificationReducer,
  SetSenderDomain: setSenderDomainReducer,
  VerifySenderDomain: verifySenderDomainReducer,
  SendTestEmail: sendTestEmailReducer,
  ResetSenderDomain: resetSenderDomainReducer,
  SenderVerificationKeys: getSenderVerificationKeysReducer,
  SendSenderVerificationKeysEmail: sendSenderVerificationKeysEmailReducer,
  SetSenderIntegrationStatus: setSenderIntegrationStatusReducer,
  TrackOptionsStatus: getTrackOptionsStatusReducer,
  SetTrackOptionsStatus: setTrackOptionsStatusReducer,
  GetDedicatedIP: getDedicatedIPReducer,
  AllocateDedicatedIP: postAllocateDedicatedIPReducer
});

export interface IRSettings {
  SenderVerificationSettings: IGetSenderVerificationSettings;
  SetSenderDomain: ISetSenderDomain;
  VerifySenderDomain: IVerifySenderDomain;
  SendTestEmail: ISendTestEmail;
  ResetSenderDomain: IResetSenderDomain;
  SenderVerificationKeys: IGetSenderDomainVerificationKeys;
  SendSenderVerificationKeysEmail: ISendSenderDomainVerificationKeysEmail;
  SetSenderIntegrationStatus: ISetSenderIntegrationStatus;
  TrackOptionsStatus: IGetTrackOptionsStatus;
  SetTrackOptionsStatus: ISetTrackOptionsStatus;
  GetDedicatedIP: IGetDedicatedIP;
  AllocateDedicatedIP: IAllocateDedicatedIP;
}
