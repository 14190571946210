import * as React from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import logoWhite from "../../assets/images/logo.svg";
import { Dropdown, Icon, Label, Loader } from "semantic-ui-react";
import "./Navigation.scss";
import { RootSchema } from "../../redux/reducers";
import { connect } from "react-redux";
import { IGetProfile } from "../../redux/actions/user";
import { IGetProjectList } from "src/redux/actions/projects";
import { appStateAction, IAppState } from "src/redux/actions/default";
import { Dispatch } from "redux";
import { IGetAccountLimits } from "src/redux/actions/account";

export interface NavigationProps extends RouteComponentProps {
  hidePaths?: string[];
  isOnboardingView?: boolean;
  profile: IGetProfile;
  projects: IGetProjectList;
  appState: IAppState;
  accountLimits: IGetAccountLimits;

  updateApp: (args: IAppState) => void;
}

export interface NavigationState {}

class Navigation extends React.Component<NavigationProps, NavigationState> {
  render() {
    const { hidePaths, location, profile } = this.props;
    if (
      this.props.appState.isProjectViewOrAddView ||
      (hidePaths && hidePaths.includes(location.pathname))
    ) {
      return null;
    }

    return (
      <React.Fragment>
        <header className="d-flex justify-content-between navigation">
          <nav>
            <ul className="d-flex align-items-stretch">
              <li className="d-flex align-items-stretch">
                <div className="logo d-flex align-items-center">
                  <Link to={"/dashboard"}>
                    <img src={logoWhite} alt="Mailazy" title="Mailazy" />
                  </Link>
                </div>
              </li>
              {/* <li className="d-flex align-items-stretch">
              <NavLink to="/dashboard" className="d-flex align-items-center">
                Dashboard
              </NavLink>
            </li>
            <li className="d-flex align-items-stretch">
              <NavLink to="/settings" className="d-flex align-items-center">
                Settings
              </NavLink>
            </li>
            <li className="d-flex align-items-stretch">
              <NavLink to="/logs" className="d-flex align-items-center">
                Logs
              </NavLink>
            </li>
            <li className="d-flex align-items-stretch">
              <NavLink to="/test" className="d-flex align-items-center">
                Test Email
              </NavLink>
            </li> */}
            </ul>
          </nav>
          <nav>
            <ul className="d-flex align-items-stretch">
              {/* <li className="d-flex align-items-center">
              Hi, {profile.FullName || "User"}!
            </li> */}
              {this.props.accountLimits.state === "loading" ? (
                <Loader
                  active
                  inline
                  indeterminate
                  className="mt-3 mr-5"
                  size="medium"
                />
              ) : this.props.projects && this.props.projects.data ? (
                <>
                  <li className="d-flex align-items-stretch">
                    <Dropdown
                      icon="chevron down"
                      text={this._getDefaultProjectName()}
                      className="d-flex align-items-center"
                    >
                      <Dropdown.Menu direction="left">
                        <Dropdown.Item
                          onClick={this._navigateToProjectsPage}
                          disabled={
                            !this.props.accountLimits
                              .is_project_management_enabled
                          }
                        >
                          Projects
                          <Icon className="ml-3" name="chevron right" />
                          {!this.props.accountLimits
                            .is_pro_subscription_active ? (
                            <Label color="orange" horizontal>
                              PRO
                            </Label>
                          ) : null}
                        </Dropdown.Item>
                        <Dropdown.Divider className="mt-0" />

                        {this.props.projects.data.map(value => {
                          return (
                            <Dropdown.Item
                              key={value.id}
                              content={value.name}
                              onClick={() => {
                                this._handleProjectSwitch(value.id);
                              }}
                              disabled={
                                value.id === this.props.appState.projectId ||
                                !this.props.accountLimits
                                  .is_project_management_enabled
                              }
                            />
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </>
              ) : null}

              <li className="d-flex align-items-stretch">
                <Dropdown
                  icon="chevron down"
                  text="My Account"
                  className="d-flex align-items-center"
                >
                  <Dropdown.Menu direction="left">
                    {profile && profile.Email && profile.Email[0].Value ? (
                      <>
                        <Dropdown.Item
                          disabled
                          content={
                            profile.FullName ? (
                              <h6>
                                {profile.FullName} <br />
                                {profile.Email[0].Value}
                              </h6>
                            ) : (
                              <h6>{profile.Email[0].Value} </h6>
                            )
                          }
                        />
                        <Dropdown.Divider className="mt-0" />
                      </>
                    ) : null}

                    <Dropdown.Item
                      content={
                        <Link to="/account-settings">Account Settings</Link>
                      }
                    />
                    <Dropdown.Item
                      content={
                        <Link to="/account-subscription">
                          Account Subscription
                        </Link>
                      }
                    />
                    {/* <Dropdown.Item
                      content={<Link to="/redeem-promo">Redeem Promo</Link>}
                    /> */}
                    <Dropdown.Item
                      onClick={() => this.props.history.push("/logout")}
                      content={<Link to="/logout">Logout</Link>}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </nav>
        </header>
      </React.Fragment>
    );
  }

  private _handleProjectSwitch(id: string | undefined) {
    if (id) {
      this.props.updateApp({ projectId: id });
      sessionStorage.setItem("project_id", id);
      this.props.history.push("/dashboard");
      window.location.reload();
    }
  }

  private _navigateToProjectsPage = () => {
    this.props.updateApp({ isProjectViewOrAddView: true });
    this.props.history.push("/projects");
  };

  private _getDefaultProjectName = (): string => {
    if (this.props.appState.projectId && this.props.projects.data) {
      for (let i = 0; i < this.props.projects.data.length; i++) {
        const element = this.props.projects.data[i];
        if (element.id === this.props.appState.projectId && element.name) {
          return element.name;
        }
      }
    }
    return "";
  };
}

const mapState = (state: RootSchema) => {
  return {
    profile: state.user.profile,
    projects: state.Project.List,
    appState: state.default.appState,
    accountLimits: state.account.Limits
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    updateApp: (args: IAppState) => dispatch(appStateAction.success(args))
  };
};

export default connect(mapState, mapDispatch)(withRouter(Navigation));
