import { combineReducers } from "redux";
import { IAction } from "../../schema";
import { accountConst, IGetAccountLimits } from "../actions/account";

const getAccountLimitsReducer = (
  state: IGetAccountLimits = {},
  action: IAction<IGetAccountLimits>
): IGetAccountLimits => {
  const { type, payload } = action;

  if (type === accountConst.GET_ACCOUNT_LIMITS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, ...payload, state: "success" };
      default:
        return state;
    }
  }

  return state;
};

export const rootAccountReducer = combineReducers({
  Limits: getAccountLimitsReducer
});

export interface IRAccount {
  Limits: IGetAccountLimits;
}
