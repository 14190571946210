import * as React from "react";
import { useLocation } from "react-router-dom";

export interface FooterProps {
  hidePaths?: string[];
  isOnboardingView?: boolean;
}

const Footer: React.FC<FooterProps> = React.memo(props => {
  const location = useLocation();
  const { hidePaths, isOnboardingView } = props;
  if (
    isOnboardingView ||
    (hidePaths && hidePaths.includes(location.pathname))
  ) {
    return null;
  }

  return (
    <footer className="footer d-flex align-items-center justify-content-center">
      <p>
        <i className="icon copyright outline"></i> {new Date().getFullYear()}{" "}
        All Rights Reserved
      </p>
    </footer>
  );
});

export default Footer;
