import { createAction, ProjectArgs } from ".";
import { IAPIError } from "../../schema";

export const suppressionConst = {
  GET_SUPPRESSION_BOUNCE_LIST: "GET_SUPPRESSION_BOUNCE_LIST",
  GET_SUPPRESSION_SPAM_LIST: "GET_SUPPRESSION_SPAM_LIST",
  GET_SUPPRESSION_UNSUBSCRIBE_LIST: "GET_SUPPRESSION_UNSUBSCRIBE_LIST",
  DELETE_SUPPRESSION_BOUNCE: "DELETE_SUPPRESSION_BOUNCE"
};

export interface ISuppressionBounce {
  data?: Array<ISuppressionBounceList>;
  count?: Number;
}

export interface ISuppressionBounceList {
  id?: string;
  date?: Date;
  address?: string;
  reason?: string;
}

export interface IGetSuppressionBounces extends ISuppressionBounce, IAPIError {}
export interface IGetSuppressionBounceArgs extends ProjectArgs {
  token: string;
  page?: number;
}

export const getSuppressionAction = createAction<
  IGetSuppressionBounces,
  IGetSuppressionBounceArgs
>(suppressionConst.GET_SUPPRESSION_BOUNCE_LIST);

// GET Spam Reports
export interface ISuppressionSpam {
  data?: Array<ISuppressionSpamList>;
  count?: Number;
}

export interface ISuppressionSpamList {
  sent_time?: string;
  mail_from?: Date;
  rcpt_to?: string;
  received_at?: string;
}

export interface IGetSuppressionSpamReports
  extends ISuppressionSpam,
    IAPIError {}
export interface IGetSuppressionSpamReportsArgs extends ProjectArgs {
  token: string;
  page?: number;
}

export const getSuppressionSpamReportsAction = createAction<
  IGetSuppressionSpamReports,
  IGetSuppressionSpamReportsArgs
>(suppressionConst.GET_SUPPRESSION_SPAM_LIST);

// GET Unsubscribe Reports
export interface ISuppressionUnsubscribe {
  data?: Array<ISuppressionUnsubscribeList>;
  count?: Number;
}

export interface ISuppressionUnsubscribeList {
  received_at?: string;
  from_address?: Date;
  unsubscribe_address?: string;
}

export interface IGetSuppressionUnsubscribeReports
  extends ISuppressionUnsubscribe,
    IAPIError {}
export interface IGetSuppressionUnsubscribeReportsArgs extends ProjectArgs {
  token: string;
  page?: number;
}

export const getSuppressionUnsubscribeReportsAction = createAction<
  IGetSuppressionUnsubscribeReports,
  IGetSuppressionUnsubscribeReportsArgs
>(suppressionConst.GET_SUPPRESSION_UNSUBSCRIBE_LIST);

// Delete Bounce Suppression
export interface IDeleteSuppressionBounce extends IAPIError {
  removed?: boolean;
}
export interface IDeleteSuppressionBounceArgs extends ProjectArgs {
  token: string;
  id?: string;
}

export const deleteSuppressionBounceAction = createAction<
  IDeleteSuppressionBounce,
  IDeleteSuppressionBounceArgs
>(suppressionConst.DELETE_SUPPRESSION_BOUNCE);
