import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { AdminHttp } from "../../services/http";
import {
  getVerificationSettingsAction,
  IGetSenderVerificationArgs,
  IGetSenderVerificationSettings,
  setSenderDomainAction,
  ISetSenderDomain,
  ISetSenderDomainArgs,
  verifySenderDomainAction,
  IVerifySenderDomain,
  IVerifySenderDomainArgs,
  ISendTestEmail,
  ISendTestEmailArgs,
  sendTestEmailAction,
  settingsConst,
  IResetSenderDomain,
  IResetSenderDomainArgs,
  resetSenderDomainAction,
  IGetSenderDomainVerificationKeysArgs,
  getSenderDomainVerificationKeys,
  IGetSenderDomainVerificationKeys,
  ISendSenderDomainVerificationKeysEmailArgs,
  sendSenderDomainVerificationKeysEmail,
  ISendSenderDomainVerificationKeysEmail,
  ISetSenderIntegrationStatusArgs,
  setSenderIntegrationStatus,
  ISetSenderIntegrationStatus,
  IGetTrackOptionsStatusArgs,
  getTrackOptionsStatus,
  ISetTrackOptionsStatusArgs,
  setTrackOptionsStatus,
  IGetTrackOptionsStatus,
  ISetTrackOptionsStatus,
  allocateDedicatedIP,
  getDedicatedIP,
  IAllocateDedicatedIP,
  IAllocateDedicatedIPArgs,
  IGetDedicatedIP,
  IGetDedicatedIPArgs
} from "../actions/settings";

function* getVerificationSettingsHandler(args: IGetSenderVerificationArgs) {
  try {
    yield put(getVerificationSettingsAction.loading());

    const resp: IGetSenderVerificationSettings = yield AdminHttp({
      token: args.token
    }).get("/sender");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getVerificationSettingsAction.success(resp));
  } catch (e) {
    yield put(getVerificationSettingsAction.error(e.message));
  }
}

export function* watchGetVerificationSettings() {
  while (true) {
    const { payload }: IAction<IGetSenderVerificationArgs> = yield take(
      settingsConst.GET_SENDER_VERIFICATION_SETTINGS
    );
    yield call(getVerificationSettingsHandler, payload);
  }
}

function* setSenderDomainHandler(args: ISetSenderDomainArgs) {
  try {
    yield put(setSenderDomainAction.loading());

    const resp: ISetSenderDomain = yield AdminHttp({
      token: args.token
    }).post("/sender/domain", {}, JSON.stringify({ domain: args.domain }));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(setSenderDomainAction.success(resp));
  } catch (e) {
    yield put(setSenderDomainAction.error(e.message));
  }
}

export function* watchSetSenderDomain() {
  while (true) {
    const { payload }: IAction<ISetSenderDomainArgs> = yield take(
      settingsConst.SET_SENDER_DOMAIN
    );
    yield call(setSenderDomainHandler, payload);
  }
}

function* verifySenderDomainHandler(args: IVerifySenderDomainArgs) {
  try {
    yield put(verifySenderDomainAction.loading());

    const resp: IVerifySenderDomain = yield AdminHttp({
      token: args.token
    }).get(`/sender/verify?domain=${args.domain}`);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(verifySenderDomainAction.success(resp));
  } catch (e) {
    yield put(verifySenderDomainAction.error(e.message));
  }
}

export function* watchVerifySenderDomain() {
  while (true) {
    const { payload }: IAction<IVerifySenderDomainArgs> = yield take(
      settingsConst.VERIFY_SENDER_DOMAIN
    );
    yield call(verifySenderDomainHandler, payload);
  }
}

function* sendTestEmailHandler(args: ISendTestEmailArgs) {
  try {
    yield put(sendTestEmailAction.loading());

    const resp: ISendTestEmail = yield AdminHttp({
      token: args.token
    }).post(
      "/sender/test_email",
      {},
      JSON.stringify({
        from: args.from,
        rcpt_to: args.rcpt_to
      })
    );

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(sendTestEmailAction.success(resp));
  } catch (e) {
    yield put(sendTestEmailAction.error(e.message));
  }
}

export function* watchSendTestEmailSPF() {
  while (true) {
    const { payload }: IAction<ISendTestEmailArgs> = yield take(
      settingsConst.SEND_TEST_EMAIL
    );
    yield call(sendTestEmailHandler, payload);
  }
}

function* resetSenderDomainHandler(args: IResetSenderDomainArgs) {
  try {
    yield put(resetSenderDomainAction.loading());

    const resp: IResetSenderDomain = yield AdminHttp({
      token: args.token
    }).put("/sender/reset", {}, JSON.stringify({ domain: args.domain }));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(resetSenderDomainAction.success(resp));
  } catch (e) {
    yield put(resetSenderDomainAction.error(e.message));
  }
}

export function* watchResetSenderDomain() {
  while (true) {
    const { payload }: IAction<IResetSenderDomainArgs> = yield take(
      settingsConst.RESET_SENDER_DOMAIN
    );
    yield call(resetSenderDomainHandler, payload);
  }
}

function* getSenderDomainVerificationKeysHandler(
  args: IGetSenderDomainVerificationKeysArgs
) {
  try {
    yield put(getSenderDomainVerificationKeys.loading());

    const resp: IGetSenderDomainVerificationKeys = yield AdminHttp({
      token: args.token
    }).get(`/sender/dns_keys?domain=${args.domain}`);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getSenderDomainVerificationKeys.success(resp));
  } catch (e) {
    yield put(getSenderDomainVerificationKeys.error(e.message));
  }
}

export function* watchGetSenderDomainVerificationKeys() {
  while (true) {
    const {
      payload
    }: IAction<IGetSenderDomainVerificationKeysArgs> = yield take(
      settingsConst.GET_SENDER_VERIFICATION_KEYS
    );
    yield call(getSenderDomainVerificationKeysHandler, payload);
  }
}

function* sendSenderVerificationKeysEmailHandler(
  args: ISendSenderDomainVerificationKeysEmailArgs
) {
  try {
    yield put(sendSenderDomainVerificationKeysEmail.loading());

    const resp: ISendSenderDomainVerificationKeysEmail = yield AdminHttp({
      token: args.token
    }).post("/sender/dns_keys/email", {}, JSON.stringify(args));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(sendSenderDomainVerificationKeysEmail.success(resp));
  } catch (e) {
    yield put(sendSenderDomainVerificationKeysEmail.error(e.message));
  }
}

export function* watchSendSenderVerificationKeysEmail() {
  while (true) {
    const {
      payload
    }: IAction<ISendSenderDomainVerificationKeysEmailArgs> = yield take(
      settingsConst.SEND_SENDER_VERIFICATION_KEYS_EMAIL
    );
    yield call(sendSenderVerificationKeysEmailHandler, payload);
  }
}

function* setSenderIntegrationStatusHandler(
  args: ISetSenderIntegrationStatusArgs
) {
  try {
    yield put(setSenderIntegrationStatus.loading());

    const resp: ISetSenderIntegrationStatus = yield AdminHttp({
      token: args.token
    }).post("/sender/integration_status", {}, JSON.stringify(args));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(setSenderIntegrationStatus.success(resp));
  } catch (e) {
    yield put(setSenderIntegrationStatus.error(e.message));
  }
}

export function* watchSetSenderIntegrationStatus() {
  while (true) {
    const { payload }: IAction<ISetSenderIntegrationStatusArgs> = yield take(
      settingsConst.SET_SENDER_INTEGRATION_STATUS
    );
    yield call(setSenderIntegrationStatusHandler, payload);
  }
}

function* getTrackOptionsStatusHandler(args: IGetTrackOptionsStatusArgs) {
  try {
    yield put(getTrackOptionsStatus.loading());

    const resp: IGetTrackOptionsStatus = yield AdminHttp({
      token: args.token
    }).get("/sender/track_options");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getTrackOptionsStatus.success(resp));
  } catch (e) {
    yield put(getTrackOptionsStatus.error(e.message));
  }
}

export function* watchGetTrackOptionsStatus() {
  while (true) {
    const { payload }: IAction<IGetTrackOptionsStatusArgs> = yield take(
      settingsConst.GET_TRACK_OPTIONS_STATUS
    );
    yield call(getTrackOptionsStatusHandler, payload);
  }
}

function* setTrackOptionsStatusHandler(args: ISetTrackOptionsStatusArgs) {
  try {
    yield put(setTrackOptionsStatus.loading());

    const resp: ISetTrackOptionsStatus = yield AdminHttp({
      token: args.token
    }).put("/sender/track_options", {}, JSON.stringify(args));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(setTrackOptionsStatus.success(resp));
  } catch (e) {
    yield put(setTrackOptionsStatus.error(e.message));
  }
}

export function* watchSetTrackOptionsStatus() {
  while (true) {
    const { payload }: IAction<ISetTrackOptionsStatusArgs> = yield take(
      settingsConst.SET_TRACK_OPTIONS_STATUS
    );
    yield call(setTrackOptionsStatusHandler, payload);
  }
}

function* getDedicatedIPHandler(args: IGetDedicatedIPArgs) {
  try {
    yield put(getDedicatedIP.loading());

    const resp: IGetDedicatedIP = yield AdminHttp({
      token: args.token
    }).get("/dedicated_ip", {});

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getDedicatedIP.success(resp));
  } catch (e) {
    yield put(getDedicatedIP.error(e.message));
  }
}

export function* watchDedicatedIP() {
  while (true) {
    const { payload }: IAction<IGetDedicatedIPArgs> = yield take(
      settingsConst.GET_DEDICATED_IP
    );
    yield call(getDedicatedIPHandler, payload);
  }
}

function* postAllocateDedicatedIPHandler(args: IAllocateDedicatedIPArgs) {
  try {
    yield put(allocateDedicatedIP.loading());

    const resp: IAllocateDedicatedIP = yield AdminHttp({
      token: args.token
    }).get("/assign_dedicated_ip", {});

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(allocateDedicatedIP.success(resp));
  } catch (e) {
    yield put(allocateDedicatedIP.error(e.message));
  }
}

export function* watchAllocateDedicatedIP() {
  while (true) {
    const { payload }: IAction<IAllocateDedicatedIPArgs> = yield take(
      settingsConst.ALLOCATE_DEDICATED_IP
    );
    yield call(postAllocateDedicatedIPHandler, payload);
  }
}
