import { createAction } from ".";
import { IAPIError } from "../../schema";

export const subscriptionConst = {
  GET_SUBSCRIBE_URL: "GET_SUBSCRIBE_URL",
  GET_SUBSCRIBE_EDIT_URL: "GET_SUBSCRIBE_EDIT_URL",
  GET_SUBSCRIPTION_STATUS: "GET_SUBSCRIPTION_STATUS",
  GET_PRICING: "GET_PRICING",
  REDEEM_PROMO: "REDEEM_PROMO",
  GET_REDEEMED_PROMO: "GET_REDEEMED_PROMO",
  GET_INVOICES: "GET_INVOICES",
  GET_SUBSCRIPTION_MIGRATION_STATUS: "GET_SUBSCRIPTION_MIGRATION_STATUS",
  GET_SUBSCRIBE_MIGRATION_URL: "GET_SUBSCRIBE_MIGRATION_URL"
};

export interface ISubscribeURL {
  redirect_to?: string;
}

export interface IGetSubscriptionURL extends ISubscribeURL, IAPIError {}
export interface IGetSubscriptionURLArgs {
  token: string;
  currency_id?: string;
  origin?: string;
}

export const getSubscriptionURLAction = createAction<
  IGetSubscriptionURL,
  IGetSubscriptionURLArgs
>(subscriptionConst.GET_SUBSCRIBE_URL);

// Edit Subscription URL
export interface IGetSubscriptionEditURL extends ISubscribeURL, IAPIError {}
export interface IGetSubscriptionEditURLArgs extends IGetSubscriptionURLArgs {}

export const getSubscriptionEditURLAction = createAction<
  IGetSubscriptionEditURL,
  IGetSubscriptionEditURLArgs
>(subscriptionConst.GET_SUBSCRIBE_EDIT_URL);

// Subscription Status
export interface IGetSubscriptionStatus extends IAPIError {
  subscribed?: boolean;
  promo_code?: string;
  details?: IGetSubscriptionDetails;
  is_subscription_required?: boolean;
  is_trial_period_active?: boolean;
}
export interface IGetSubscriptionDetails {
  created?: number;
  current_period_end?: number;
  current_period_start?: number;
  cards?: Array<ICardDetails>;
  address?: IAddressDetails;
  subscription_name?: string;
  is_metered?: boolean;
  limits?: ISubscriptionLimits;
}

export interface ISubscriptionLimits {
  per_month?: number;
  per_day?: number;
  trial_period_quota?: number;
}

export interface ICardDetails {
  last4?: string;
}
export interface IAddressDetails {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  postal_code?: string;
  state?: string;
}
export interface IGetSubscriptionStatusArgs {
  token: string;
}

export const getSubscriptionStatusAction = createAction<
  IGetSubscriptionStatus,
  IGetSubscriptionStatusArgs
>(subscriptionConst.GET_SUBSCRIPTION_STATUS);

// GET Pricing
export interface IGetPricing extends IAPIError {
  plans?: Array<Plans>;
}

export interface Plans {
  id: string;
  name: string;
  description: string;
  prices: Array<Prices>;
  feature_keywords: Array<string>;
  is_discontinued?: boolean;
}

export interface Prices {
  id: string;
  currency?: string;
  volume_price?: Array<VolumePrice>;
  flat_price?: number;
}

export interface VolumePrice {
  upto?: number;
  price?: number;
}

export interface IGetPricingArgs {
  token: string;
}
export const getPricingAction = createAction<IGetPricing, IGetPricingArgs>(
  subscriptionConst.GET_PRICING
);

// POST Redeem Promo
export interface IRedeemPromo extends IAPIError {
  redeemed?: boolean;
}

export interface IRedeemPromoArgs {
  token: string;
  code?: string;
}

export const redeemPromoAction = createAction<IRedeemPromo, IRedeemPromoArgs>(
  subscriptionConst.REDEEM_PROMO
);

// GET Redeemed Promo
export interface IGetRedeemedPromo extends IAPIError {
  redeemed_at?: Date;
  promo_code?: string;
}

export interface IGetRedeemedPromoArgs {
  token: string;
}

export const getRedeemedPromoAction = createAction<
  IGetRedeemedPromo,
  IGetRedeemedPromoArgs
>(subscriptionConst.GET_REDEEMED_PROMO);

// GET Invoices
export interface IGetInvoices extends IAPIError {
  data?: Array<InvoiceData>;
}

export interface InvoiceData {
  status: string;
  invoice_url: string;
  due_date: number;
  amount_due: number;
  currency: string;
  invoice_number: string;
  paid: boolean;
  period_start: number;
  period_end: number;
}

export interface IGetInvoicesArgs {
  token: string;
}

export const getInvoicesAction = createAction<IGetInvoices, IGetInvoicesArgs>(
  subscriptionConst.GET_INVOICES
);

// Get Stripe Migration Status
export interface IGetSubscriptionMigrationStatus extends IAPIError {
  should_migrate?: boolean;
  migrated?: boolean;
}

export interface IGetSubscriptionMigrationStatusArgs {
  token: string;
}

export const getSubscriptionMigrationStatusAction = createAction<
  IGetSubscriptionMigrationStatus,
  IGetSubscriptionMigrationStatusArgs
>(subscriptionConst.GET_SUBSCRIPTION_MIGRATION_STATUS);

// Get Subscription Migrate URL
export interface IGetSubscriptionMigrateURL extends ISubscribeURL, IAPIError {}
export interface IGetSubscriptionMigrateURLArgs {
  token: string;
  origin?: string;
}

export const getSubscriptionMigrateURLAction = createAction<
  IGetSubscriptionMigrateURL,
  IGetSubscriptionMigrateURLArgs
>(subscriptionConst.GET_SUBSCRIBE_MIGRATION_URL);
