import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Dispatch } from "redux";
import { Message } from "semantic-ui-react";
import {
  INetwork,
  updateNetworkAction,
  IAppState,
  appStateAction
} from "../../redux/actions/default";
import {
  getProfileAction,
  IGetProfile,
  IGetProfileArgs
} from "../../redux/actions/user";
import { RootSchema } from "../../redux/reducers";
import Routes, { FALLBACK } from "../../routes";
import { getCookie } from "../../utils/urls";
import Navigation from "../Navigation";
import SidebarNavigation from "../SidebarNavigation";
import Footer from "../Navigation/footer";
import "./app.scss";
import CacheBuster, { ICacheBusterState } from "./cacheBuster";
import {
  getProjectListAction,
  IGetProjectList,
  IGetProjectListArgs
} from "src/redux/actions/projects";
import {
  getAccountLimitsAction,
  IGetAccountLimits,
  IGetAccountLimitsArgs
} from "src/redux/actions/account";

const FirstLoginOnboardingKey = "fl_ov";

export interface AppProps extends RouteComponentProps {
  profile: IGetProfile;
  getProfile: (args: IGetProfileArgs) => void;
  network: INetwork;
  updateNetwork: (args: INetwork) => void;
  appState: IAppState;
  updateApp: (args: IAppState) => void;
  projects: IGetProjectList;
  // getProjects: (args: IGetProjectListArgs) => void;
  accountLimits: IGetAccountLimits;
  // getAccountLimits: (args: IGetAccountLimitsArgs) => void;
}

class App extends React.Component<AppProps> {
  private reloadOnConnection = false;
  private isInitLoading = false;

  constructor(props: AppProps) {
    super(props);
    // const { location } = props;
    // if (
    //   location.pathname === "/onboarding/steps" ||
    //   location.pathname === "/setup-wizard"
    // ) {
    //   props.updateApp({ isOnboardingView: true });
    //   localStorage.setItem(FirstLoginOnboardingKey, "1");
    // }
    const token = getCookie("accessToken");
    if (navigator.onLine && token) {
      this.isInitLoading = true;
      // this.props.getProjects({ token });
      // this.props.getAccountLimits({ token });
    }
  }

  componentDidMount() {
    this.reloadOnConnection = navigator.onLine === false;

    const token = getCookie("accessToken");
    if (navigator.onLine && token) {
      this.props.getProfile({ token });
      // this.props.getProjects({ token });
    }

    const { updateNetwork } = this.props;
    window.ononline = () => {
      if (this.reloadOnConnection) {
        return window.location.reload();
      }
      updateNetwork({ connected: true });
    };
    window.onoffline = () => {
      updateNetwork({ connected: false });
    };
  }

  componentDidUpdate(pProps: AppProps) {
    if (pProps.profile.state === "loading") {
      const { profile } = this.props;
      if (profile.state === "error") {
        toast.error(profile.error || "Session expired. Please login again!");
        const redirect = window.location.pathname + window.location.search;
        this.props.history.push(`/logout?redirect=${redirect}`);
      } else if (
        profile.state === "success" &&
        profile.FirstLogin &&
        localStorage.getItem(FirstLoginOnboardingKey) !== "1"
      ) {
        localStorage.setItem(FirstLoginOnboardingKey, "1"); // first login onboarding view
        this.props.updateApp({ isOnboardingView: true });
        this.props.history.push("/dashboard");
      }
    }
    if (pProps.projects.state === "loading") {
      const { projects } = this.props;
      if (projects.state === "success" && projects.data) {
        this.isInitLoading = false;
        const sessionProjectId = sessionStorage.getItem("project_id");
        if (
          sessionProjectId &&
          projects.data.some(obj => obj.id === sessionProjectId)
        ) {
          this.props.updateApp({ projectId: sessionProjectId });
        } else {
          for (let index = 0; index < projects.data.length; index++) {
            const element = projects.data[index];
            if (element.is_default && element.id) {
              this.props.updateApp({ projectId: element.id });
              sessionStorage.setItem("project_id", element.id);
              window.location.reload();
            }
          }
        }
      }
    }

    if (this.props.location !== pProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    if (!this.props.projects.data || !this.props.appState.projectId) {
      const token = getCookie("accessToken");
      if (token) {
        // this.props.getProjects({ token });
      }
    }
  }

  render() {
    const hidePaths = [
      // "/",
      "/login",
      "/signup",
      "/verification",
      "/forgotpassword",
      "/resetpassword"
    ];

    const {
      profile,
      projects,
      accountLimits,
      network,
      appState: { isOnboardingView }
    } = this.props;

    return (
      <CacheBuster>
        {({
          loading,
          isLatestVersion,
          refreshCacheAndReload
        }: ICacheBusterState) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <React.Fragment>
              {!network.connected && (
                <Message
                  error
                  compact
                  header="Internet not connected!"
                  className="msg-no-network"
                />
              )}
              {!this.reloadOnConnection ? (
                <div className="main">
                  {profile.state === "loading" ||
                  (this.isInitLoading &&
                    (projects.state === "loading" ||
                      accountLimits.state === "loading")) ? (
                    FALLBACK
                  ) : (
                    <React.Fragment>
                      {console.log("running")}
                      <SidebarNavigation
                        isOnboardingView={isOnboardingView}
                        hidePaths={hidePaths}
                      />
                      <Navigation
                        isOnboardingView={isOnboardingView}
                        hidePaths={hidePaths}
                      />

                      <Routes />
                      <Footer
                        isOnboardingView={isOnboardingView}
                        hidePaths={hidePaths}
                      />
                    </React.Fragment>
                  )}
                </div>
              ) : null}
              <ToastContainer />
            </React.Fragment>
          );
        }}
      </CacheBuster>
    );
  }
}
const mapState = (state: RootSchema) => {
  return {
    profile: state.user.profile,
    network: state.default.network,
    appState: state.default.appState,
    projects: state.Project.List,
    accountLimits: state.account.Limits
  };
};
const mapDispath = (dispatch: Dispatch) => {
  return {
    getProfile: (args: IGetProfileArgs) =>
      dispatch(getProfileAction.dispatch(args)),
    updateNetwork: (args: INetwork) =>
      dispatch(updateNetworkAction.success(args)),
    updateApp: (args: IAppState) => dispatch(appStateAction.success(args)),
    getProjects: (args: IGetProjectListArgs) =>
      dispatch(getProjectListAction.dispatch(args)),
    getAccountLimits: (args: IGetAccountLimitsArgs) =>
      dispatch(getAccountLimitsAction.dispatch(args))
  };
};
export default connect(mapState, mapDispath)(withRouter(App));
