import { combineReducers } from "redux";
import { IAction } from "../../schema";

import {
  logsConst,
  IGetLogs,
  IGetLogsByDate,
  IGetLogsByEmail
} from "../actions/logs";

const getLogsReducer = (
  state: IGetLogs = {},
  action: IAction<IGetLogs>
): IGetLogs => {
  const { type, payload } = action;

  if (type === logsConst.GET_LOGS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        if (payload.logs == null || payload.logs.length === 0) {
          return {
            ...state,
            state: "success",
            is_first_page: payload.is_first_page,
            is_last_page: payload.is_last_page,
            has_data: false
          };
        }

        return {
          ...state,
          state: "success",
          logs: payload.logs,
          prev_cursor: payload.prev_cursor,
          next_cursor: payload.next_cursor,
          is_first_page: payload.is_first_page,
          is_last_page: payload.is_last_page,
          has_data: true
        };
      default:
        return state;
    }
  }

  return state;
};

const getLogsByDateReducer = (
  state: IGetLogsByDate = {},
  action: IAction<IGetLogsByDate>
): IGetLogsByDate => {
  const { type, payload } = action;

  if (type === logsConst.GET_LOGS_BY_DATE) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        if (payload.logs == null || payload.logs.length === 0) {
          return {
            ...state,
            state: "success",
            is_first_page: payload.is_first_page,
            is_last_page: payload.is_last_page,
            has_data: false
          };
        }

        return {
          ...state,
          state: "success",
          logs: payload.logs,
          prev_cursor: payload.prev_cursor,
          next_cursor: payload.next_cursor,
          is_first_page: payload.is_first_page,
          is_last_page: payload.is_last_page,
          has_data: true
        };
      default:
        return state;
    }
  }

  return state;
};

const getLogsByEmailReducer = (
  state: IGetLogsByEmail = {},
  action: IAction<IGetLogsByEmail>
): IGetLogsByEmail => {
  const { type, payload } = action;

  if (type === logsConst.GET_LOGS_BY_EMAIL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        if (payload.logs == null || payload.logs.length === 0) {
          return {
            ...state,
            state: "success",
            is_first_page: payload.is_first_page,
            is_last_page: payload.is_last_page,
            has_data: false
          };
        }

        return {
          ...state,
          state: "success",
          logs: payload.logs,
          prev_cursor: payload.prev_cursor,
          next_cursor: payload.next_cursor,
          is_first_page: payload.is_first_page,
          is_last_page: payload.is_last_page,
          has_data: true
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootLogsReducer = combineReducers({
  Logs: getLogsReducer,
  LogsByDate: getLogsByDateReducer,
  LogsByEmail: getLogsByEmailReducer
});

export interface IRLogs {
  Logs: IGetLogs;
  LogsByDate: IGetLogsByDate;
  LogsByEmail: IGetLogsByEmail;
}
